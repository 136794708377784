import { FunctionComponent, createContext, useContext, useEffect, useState } from "react";
import { ProviderProps, UserContextType } from "../types/context-types";
import { useAuth } from "./auth-context";
import { Drink, DrinkEntity, DrinkInsert, DrinkUpdate } from "../models/drink";
import { MedalCollection } from "../models/medal";
import { drinkscoreService } from "../services/DrinkscoreService";
import { drinkService } from "../services/DrinkService";
import { imageService } from "../services/ImageService"; // Make sure the path is correct
import { APISTATUS, ApiResult } from "../types/api";
import { User } from "../models/user";
import { userService } from "../services/UserService";

const UserContext = createContext<UserContextType | null>(null);

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (context === null) {
        throw new Error('useUser must be used within an UserProvider');
    }
    return context;
};

export const UserProvider: FunctionComponent<ProviderProps> = ({ children }) => {
    const { user, updateUserObject } = useAuth();
    const [userDrinks, setUserDrinks] = useState<Drink[]>([]);
    const [medalCollection, setMedalCollection] = useState<MedalCollection>({ bronzeMedals: 0, silverMedals: 0, goldMedals: 0 });
    const [loadingOperation, setLoadingOperation] = useState(false);

    useEffect(() => {
        const fetchUserData = async (userID: string) => {
            const drinkRes = await drinkService.getUsersDrinks(userID);
            if (drinkRes.status === APISTATUS.SUCCESS) {
                setUserDrinks(drinkRes.data);
            }

            const medalRes = await drinkscoreService.getMedalsForUser(userID);
            if (medalRes.status === APISTATUS.SUCCESS) {
                setMedalCollection(medalRes.data);
            }
        };

        if (user) {
            fetchUserData(user.id);
        }
    }, [user]);

    const toggleDrinkIsPublic = async (drink: Drink) => {
        const res = await drinkService.updateDrinkFields(drink.drink_id, { public: !drink.public });
        if (res.status === APISTATUS.SUCCESS) {
            drinkMapHelper(drink.drink_id, 'public', res.data.public);
        }
    };

    const updateUser = async (updatedUser: User, newImage?: File, wantToRemoveProfilePicture = false): Promise<ApiResult<string | User>> => {
        setLoadingOperation(true);

        try {
            if (newImage) {
                // Update user profile image
                const uploadResult = await imageService.uploadProfileImage(user.id, newImage, 3600, true);
                if (uploadResult.status === APISTATUS.FAILURE) {
                    setLoadingOperation(false);
                    return uploadResult;
                }
                updatedUser.avatar_url = uploadResult.data;


            } else if (wantToRemoveProfilePicture) {
                // Remove profile image
                const deleteResult = await imageService.deleteProfileImage(user.id);
                if (deleteResult.status === APISTATUS.FAILURE) {
                    setLoadingOperation(false);
                    return deleteResult;
                }
                updatedUser.avatar_url = undefined;
            }

            // Update user details
            const updateProfile = await userService.updateUser(user.id, updatedUser);
            if (updateProfile.status === APISTATUS.SUCCESS) {
                updateUserObject(updateProfile.data)
                setLoadingOperation(false);
                return updateProfile;
            }

            setLoadingOperation(false);
            return updateProfile;
        } catch (error) {
            console.error(error);
            setLoadingOperation(false);
            return { status: APISTATUS.FAILURE, message: 'Failed to update user' };
        }
    };

    const deleteProfilePicture = async (): Promise<ApiResult<null>> => {
        setLoadingOperation(true);
        try {
            const deletePicture = await imageService.deleteProfileImage(user.id);
            if (deletePicture.status === APISTATUS.FAILURE) {
                setLoadingOperation(false);
                return deletePicture;
            }

            const update = await userService.updateUser(user.id, { ...user, avatar_url: null });
            if (update.status === APISTATUS.SUCCESS) {
                updateUserObject({ ...user, avatar_url: undefined }); // Assuming this updates the user in your auth context
                setLoadingOperation(false);
                return { status: APISTATUS.SUCCESS, data: null, message: "Profilbild borttagen!" };
            }

            setLoadingOperation(false);
            return { status: APISTATUS.FAILURE, message: "Kunde inte ta bort profilbild" };
        } catch (error) {
            console.error(error);
            setLoadingOperation(false);
            return { status: APISTATUS.FAILURE, message: "Kunde inte ta bort profilbild" };
        }
    };

    const createDrink = async (drinkInsert: DrinkInsert, file?: File): Promise<ApiResult<null>> => {
        setLoadingOperation(true)
        if (!user) {
            setLoadingOperation(false)
            return { status: APISTATUS.FAILURE, message: "Du måste vara inloggad" }
        }
        const createResult = await drinkService.createNewDrink(user.id, drinkInsert, file)

        if (createResult.status === APISTATUS.SUCCESS) {
            const getNewDrink = await drinkService.getDrinkById(createResult.data.drink_id)

            if (getNewDrink.status === APISTATUS.SUCCESS && getNewDrink.data) {
                const newDrink = getNewDrink.data
                setUserDrinks(prev => [newDrink, ...prev]);
            }
            setLoadingOperation(false)
            return { status: APISTATUS.SUCCESS, data: null, message: "Ny drink skapad!" }
        }

        setLoadingOperation(false)
        return { status: APISTATUS.FAILURE, message: "Lyckades inte skapa drink" }
    }

    const updateDrink = async (drinkID: number, drinkUpdate: DrinkUpdate, newImage?: File): Promise<ApiResult<DrinkEntity>> => {

        setLoadingOperation(true)
        if (!user) {
            setLoadingOperation(false)
            return { status: APISTATUS.FAILURE, message: "Du måste vara inloggad för att uppdatera drink" }
        }

        const res = await drinkService.updateDrink(user.id, drinkID, drinkUpdate, newImage)
        if (res.status === APISTATUS.SUCCESS) {
            setUserDrinks(prev => prev.map(drink => {
                if (drink.drink_id === drinkID) {
                    return {
                        ...drink, drink_name: res.data.drink_name,
                        instructions: res.data.instructions,
                        ingredients: res.data.ingredients,
                        image_url: res.data.image_url
                    }
                }
                return drink
            }))
        }
        setLoadingOperation(false)
        return res
    }

    const deleteDrink = async (drinkID: number): Promise<ApiResult<null>> => {
        setLoadingOperation(true)
        if (!user) {
            setLoadingOperation(false)
            return { status: APISTATUS.FAILURE, message: "Du måste vara inloggad för att ta bort drink" }
        }


        const res = await drinkService.deleteDrink(user.id, drinkID)
        if (res.status === APISTATUS.SUCCESS) {
            setUserDrinks(prev => prev.filter(drink => drink.drink_id !== drinkID))
        }
        setLoadingOperation(false)
        return res
    }



    function drinkMapHelper<T extends keyof Drink>(id: number, field: T, value: Drink[T]) {
        setUserDrinks(prev => prev.map(drink => {
            if (drink.drink_id === id) {
                return { ...drink, [field]: value };
            }
            return drink;
        }));
    }

    const value = {
        user,
        userDrinks,
        medalCollection,
        createDrink,
        updateDrink,
        deleteDrink,
        toggleDrinkIsPublic,
        deleteProfilePicture,
        updateUser,
        loadingOperation,
    };

    return (<UserContext.Provider value={value}>{children}</UserContext.Provider>);
};
