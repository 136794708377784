import React from "react";
import { Drink } from "../../models/drink";
import DrinkCard from "../cards/drink-card";
import styles from "../../css/drink-list.module.css"; // Import the CSS module
import { useNavigate } from "react-router-dom";

type DrinkListProps = {
  drinks: Drink[];
};

const DrinkList: React.FC<DrinkListProps> = ({ drinks }) => {
  const navigate = useNavigate();

  const handleDrinkSelect = (drink: Drink) => {
    navigate(`/drinks/${drink.drink_id}`);
  };

  return (
    <div className={styles.gridContainer}>
      {drinks.map((drink) => (
        <DrinkCard
          key={drink.drink_id}
          drink={drink}
          onDrinkSelect={handleDrinkSelect}
          showProfile={true}
        />
      ))}
    </div>
  );
};

export default DrinkList;
