import React from 'react';
import styles from '../../css/profile-banner.module.css';
import { MedalCollection } from '../../models/medal';
import { User } from '../../models/user';
import { Badge } from '../badge/badge';

type Props = {
    profile: User;
    medalCollection: MedalCollection;
    borderRadius: number;
    avatar_height: number;
};

export const ProfileBanner: React.FC<Props> = ({ profile, medalCollection }) => {
    return (
        <div className={styles.container}>

            <div className={styles.imageContainer}>
                {profile.avatar_url && (
                    <img src={profile.avatar_url} alt="Profile Avatar" className={styles.profileImage} />
                )}

                <p className={styles.profileName}>{profile.full_name}</p>
            </div>
            <div className={styles.statsContainer}>
                <div className={styles.statItem}>
                    <Badge medalType="bronze" />
                    <h3>{medalCollection.bronzeMedals}</h3>
                </div>
                <div className={styles.statItem}>
                    <Badge medalType="silver" />
                    <h3>{medalCollection.silverMedals}</h3>
                </div>
                <div className={styles.statItem}>
                    <Badge medalType="gold" />
                    <h3>{medalCollection.goldMedals}</h3>
                </div>
            </div>
        </div>
    );
};

export default ProfileBanner;
