import { Drink } from "../models/drink"
import { EventForm, DrinkEvent, EventItem } from "../models/event"
import { Participant } from "../models/participant"
import { supabase } from "../supabase/supabase"
import { ApiResult, APISTATUS } from "../types/api"
import { imageService } from "./ImageService"


const EVENT_CREATED_SUCCESS_MESSAGE = "Nytt event skapat!"
const EVENT_UPDATED_SUCCESS_MESSAGE = "Event uppdaterat!"
const EVENT_DELETED_SUCCESS_MESSAGE = "Event borttaget!"
const EVENT_GET_SUCCESS_MESSAGE = "Alla event hämtade!"


const EVENT_CREATED_FAIL_MESSAGE = "Kunde inte skapa nytt event"
const EVENT_UPDATED_FAIL_MESSAGE = "Kunde inte uppdatera event"
const EVENT_DELETED_FAIL_MESSAGE = "Lyckades inte ta bort event"
const EVENT_GET_FAIL_MESSAGE = "Kunde inte hämta alla event"


const EVENT_CREATED_PARTIAL_MESSAGE = "Nytt event skapat men kunde inte ladda upp bild"
const EVENT_UPDATED_PARTIAL_MESSAGE = undefined
const EVENT_DELETED_PARTIAL_MESSAGE = "Event borttaget men kunde inte ta bort bild"



export class EventService {


    async isOwnerOfEvent(userID: string, eventID: number): Promise<ApiResult<boolean>> {
        try {
            const { data } = await supabase
                .from('events')
                .select("*", { count: 'exact' })
                .eq('participant_id', userID)
                .eq('event_id', eventID)
                .limit(1)
                .maybeSingle();


            if (data) {
                return { status: APISTATUS.SUCCESS, data: true, message: "Du är ägare av eventet" };
            } else {
                return { status: APISTATUS.SUCCESS, data: false, message: "Du är inte ägare av eventet" };
            }
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Lyckades ej att kolla ifall du var deltagare" };
        }
    }


    async getParticipantsForEvent(eventID: number): Promise<ApiResult<Participant[]>> {
        try {
            const { data, error } = await supabase.from('participant_view').select().eq('event_id', eventID)

            if (data) return { status: APISTATUS.SUCCESS, data: data, message: "Alla deltagare hämtade" }

            throw error
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Kunde inte hämta alla deltagare" }
        }
    }

    async isParticipantInEvent(userID: string, eventID: number): Promise<ApiResult<boolean>> {
        try {
            const { data, error } = await supabase
                .from('event_participants')
                .select("*", { count: 'exact' })
                .eq('participant_id', userID)
                .eq('event_id', eventID)
                .limit(1)
                .maybeSingle();


            if (data) {
                return { status: APISTATUS.SUCCESS, data: true, message: "Du är deltagare i det här eventet" };
            } else {
                return { status: APISTATUS.SUCCESS, data: false, message: "Du är inte deltagare i det här eventet" };
            }
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Lyckades ej att kolla ifall du var deltagare" };
        }
    }


    async joinEvent(userID: string, eventID: number): Promise<ApiResult<null>> {
        try {
            const { error } = await supabase.from('event_participants')
                .insert({ participant_id: userID, event_id: eventID })

            if (!error) return { status: APISTATUS.SUCCESS, data: null, message: "Du har gått med i eventet!" }

            throw error
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Lyckades inte gå med i eventet" }
        }
    }
    async leaveEvent(userID: string, eventID: number): Promise<ApiResult<null>> {
        try {
            const { error } = await supabase.from('event_participants')
                .delete()
                .eq('participant_id', userID)
                .eq('event_id', eventID)


            if (error) return { status: APISTATUS.FAILURE, message: "Kunde inte lämna eventet" }

            return { status: APISTATUS.SUCCESS, data: null, message: "Du har lämnat eventet" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Kunde inte lämna eventet" }
        }
    }

    async readyUpForEvent(userID: string, eventID: number): Promise<ApiResult<null>> {
        try {
            const { error } = await supabase.from('event_participants')
                .update({ event_status: "Confirmed" })
                .eq('participant_id', userID)
                .eq('event_id', eventID)

            if (error) return { status: APISTATUS.FAILURE, message: "Ett problem uppstod" };

            return { status: APISTATUS.SUCCESS, data: null, message: "Du har gjort dig redo" };
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Lyckades ej att bli redo" }
        }
    }

    async cancelReadyStatus(userID: string, eventID: number): Promise<ApiResult<null>> {
        try {
            const { error } = await supabase.from('event_participants')
                .update({ event_status: "Pending" })
                .eq('participant_id', userID)
                .eq('event_id', eventID)

            if (error) return { status: APISTATUS.FAILURE, message: "Ett problem uppstod" };

            return { status: APISTATUS.SUCCESS, data: null, message: "Du har gjort dig ångrat din redo status" };
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Lyckades ej att bli ångra redo" }
        }
    }



    async canPublishNewDrink(eventID: number): Promise<ApiResult<boolean>> {
        try {
            const { error, count: totalNumberOfParticipants } = await supabase
                .from('event_participants')
                .select('*', { count: 'exact' })
                .eq('event_id', eventID);

            if (!totalNumberOfParticipants) return { status: APISTATUS.FAILURE, message: "Kunde inte hämta antalet medverkare" };

            const { data: latestPublishedDrink, error: latestPublishedDrinkError } = await supabase
                .from('drinks')
                .select('drink_id')
                .not('published_at', 'is', null)
                .eq('event_id', eventID)
                .order('published_at', { ascending: false })
                .limit(1)
                .single()




            if (!latestPublishedDrink || latestPublishedDrinkError)
                return { status: APISTATUS.SUCCESS, data: true, message: "Du har tillstånd att publicera" };

            const { error: votesError, count: currentAmountOfVotesForLatestDrink } = await supabase
                .from('event_drinkscores')
                .select('*', { count: 'exact' })
                .eq('drink_id', latestPublishedDrink);


            if (votesError) return { status: APISTATUS.FAILURE, message: "Kunde inte hämta antalet röster" };

            const allowedToPublish = totalNumberOfParticipants === currentAmountOfVotesForLatestDrink

            return {
                status: APISTATUS.SUCCESS,
                data: allowedToPublish,
                message: allowedToPublish ? "Du har tillstånd att publicera ny drink" : "Du har inte tillstånd att publicera ny drink"
            }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Kunde inte utföra canPublishNewDrink" };
        }
    }



    async getCurrentPublishedDrink(eventID: number): Promise<ApiResult<Drink | null>> {
        try {
            const { data: currentDrink, error } = await supabase.from('events')
                .select(`current_drink_id`)
                .eq('id', eventID)
                .single()




            if (error) return { status: APISTATUS.FAILURE, message: EVENT_GET_FAIL_MESSAGE }

            if (currentDrink.current_drink_id) {
                const { data, error } = await supabase.from('drinks_view').select().eq('drink_id', currentDrink.current_drink_id).single()

                if (error) return { status: APISTATUS.FAILURE, message: "Kunde inte hämta den senaste drinken" }

                return { status: APISTATUS.SUCCESS, data: data, message: "Senaste drink hämtad" }
            }

            return { status: APISTATUS.SUCCESS, data: null, message: "Ingen drink publicerad" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Kunde inte hämta den senast publicerade drinken" }
        }
    }


    async createNewEvent(event: EventForm, file?: File): Promise<ApiResult<number>> {
        try {
            const date = event.when_date ? event.when_date.toDateString() : new Date().toDateString()

            const { data: newEvent, error } = await supabase.from('events')
                .insert({
                    creator_id: event.creator_id,
                    title: event.title,
                    description: event.description,
                    when_date: date,
                })
                .select()
                .single()

            if (error) return {
                status: APISTATUS.FAILURE, message: EVENT_CREATED_FAIL_MESSAGE
            }

            if (!file) return {
                status: APISTATUS.SUCCESS, data: newEvent.id, message: EVENT_CREATED_SUCCESS_MESSAGE
            }

            const eventID = newEvent.id

            const uploadImageResult = await imageService.uploadEventImage(event.creator_id, eventID, file)

            // TODO: This will return the api result, not a frontend result
            if (uploadImageResult.status === APISTATUS.FAILURE) return uploadImageResult

            const { data, error: updateError } = await supabase.from('events')
                .update({ image_url: uploadImageResult.data })
                .eq("id", eventID)


            if (updateError) return { status: APISTATUS.SUCCESS, data: eventID, message: EVENT_CREATED_PARTIAL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data: eventID, message: EVENT_CREATED_SUCCESS_MESSAGE }

        } catch (error) {
            console.error("createNewEvent", error);
            return { status: APISTATUS.FAILURE, message: EVENT_CREATED_FAIL_MESSAGE }
        }

    }

    async updateEvent(eventID: number, eventForm: EventForm, file?: File): Promise<ApiResult<null>> {
        try {
            const date = eventForm.when_date ? eventForm.when_date.toDateString() : new Date().toDateString()
            const { error } = await supabase.from('events')
                .update({ ...eventForm, when_date: date })
                .eq('id', eventID)
                .select()

            if (error) return { status: APISTATUS.FAILURE, message: EVENT_UPDATED_FAIL_MESSAGE }

            if (!file) return {
                status: APISTATUS.SUCCESS, data: null, message: EVENT_UPDATED_SUCCESS_MESSAGE
            }


            const uploadImageResult = await imageService.uploadEventImage(eventForm.creator_id, eventID, file)

            // TODO: This will return the api result, not a frontend result
            if (uploadImageResult.status === APISTATUS.FAILURE) return uploadImageResult

            const { data, error: updateError } = await supabase.from('events')
                .update({ image_url: uploadImageResult.data })
                .eq("id", eventID)


            if (updateError) return { status: APISTATUS.SUCCESS, data: null, message: EVENT_UPDATED_FAIL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data: null, message: EVENT_UPDATED_SUCCESS_MESSAGE }

        } catch (error) {
            console.error("updateEvent", error);
            return { status: APISTATUS.FAILURE, message: EVENT_UPDATED_FAIL_MESSAGE + " " + eventID }
        }
    }

    async deleteEvent(userID: string, eventID: number): Promise<ApiResult<null>> {
        try {
            const { data, error } = await supabase.from('events').delete().eq('id', eventID);
            if (error) return { status: APISTATUS.FAILURE, message: EVENT_DELETED_FAIL_MESSAGE };

            const { status, message } = await imageService.deleteEventImage(userID, eventID)

            if (status === APISTATUS.FAILURE) return { status: APISTATUS.SUCCESS, data: null, message: EVENT_DELETED_PARTIAL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data: null, message: EVENT_DELETED_SUCCESS_MESSAGE };
        } catch (error) {
            console.error("Error deleting event:", error);
            return { status: APISTATUS.FAILURE, message: EVENT_DELETED_FAIL_MESSAGE };
        }
    }


    async getAllEvents(): Promise<ApiResult<DrinkEvent[]>> {
        try {
            const { data, error } = await supabase.from('event_view').select("*").order('event_created_at', { ascending: false })

            if (error) return { status: APISTATUS.FAILURE, message: EVENT_GET_FAIL_MESSAGE };

            return { status: APISTATUS.SUCCESS, data, message: EVENT_GET_SUCCESS_MESSAGE };

        } catch (error) {
            console.error("getAllEvents", error);
            return { status: APISTATUS.FAILURE, message: EVENT_GET_FAIL_MESSAGE };
        }
    }

    async getEvent(eventID: number): Promise<ApiResult<DrinkEvent | null>> {
        try {
            const { data, error } = await supabase.from('event_view').select('*').eq('event_id', eventID).single()

            if (error) return { status: APISTATUS.FAILURE, message: "Lyckades in hämta event " + eventID }

            return { status: APISTATUS.SUCCESS, data: data, message: "OK" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Lyckades in hämta event " + eventID }
        }
    }


    async getNewEvents(): Promise<ApiResult<DrinkEvent[]>> {
        throw new Error("Method not implemented.");
    }
    async getPastEvents(): Promise<ApiResult<DrinkEvent[]>> {
        throw new Error("Method not implemented.");
    }

    async getEventItems(): Promise<ApiResult<EventItem[]>> {
        try {
            const { data, error } = await supabase.from('events').select(`id, title`)

            if (error) return { status: APISTATUS.FAILURE, message: error.message }

            return {
                status: APISTATUS.SUCCESS,
                data: data.map(item => ({ event_id: item.id, event_name: item.title })),
                message: EVENT_GET_SUCCESS_MESSAGE
            }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: EVENT_GET_FAIL_MESSAGE }
        }
    }

    async removePublishedDrinkFromEvent(eventID: number): Promise<ApiResult<null>> {
        try {
            const { error } = await supabase.from('events').update({ current_drink_id: null }).eq('id', eventID)

            if (error) return { status: APISTATUS.FAILURE, message: "Kunde inte ta bort senast publicerad drink" }

            return { status: APISTATUS.SUCCESS, data: null, message: "Senaste publicerad drink borttagen" }

        } catch (error) {
            console.error();
            return { status: APISTATUS.FAILURE, message: "Kunde inte ta bort senast publicerad drink" }
        }
    }

}

function createEventService() {
    return new EventService()
}

export const eventService = createEventService()