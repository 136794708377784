import React from 'react';
import { SlBadge } from 'react-icons/sl';
import { MedalType } from "../../models/medal";

type Props = {
    medalType: MedalType;
    size?: number;
};

export const Badge: React.FC<Props> = ({ medalType }) => {
    const rank = medalType === 'bronze' ? 3 : medalType === 'silver' ? 2 : 1;
    const color = rank === 3 ? 'chocolate' : rank === 2 ? 'silver' : 'gold';
    const size = 40
    return (
        <div>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span style={{
                    position: 'absolute',
                    color: color,
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: "3vw",
                    fontWeight: 'bold'
                }}>
                    {rank}
                </span>
                <SlBadge color={color} size={`${size}%`} />
            </div>
        </div>
    );
};

export default Badge;
