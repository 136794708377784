import React, { useMemo } from 'react';
import { Drink } from '../../models/drink';

type Props = {
    drinks: Drink[];
    selectedDrink: Drink | undefined;
    setSelectedDrink: (drink: Drink) => void;
    style?: React.CSSProperties;
};

export const UnPublishedDrinksList: React.FC<Props> = ({ drinks, selectedDrink, setSelectedDrink, style }) => {
    const radioButtons = useMemo(
        () =>
            drinks.map(drink => ({
                id: drink.drink_id.toString(),
                label: drink.drink_name,
                value: drink.drink_id.toString(),
            })),
        [drinks]
    );

    return (
        <div style={style}>
            {radioButtons.map(radioButton => (
                <div key={radioButton.id} style={{ marginBottom: '10px' }}>
                    <input
                        type="radio"
                        id={radioButton.id}
                        name="unpublished-drinks"
                        value={radioButton.value}
                        checked={selectedDrink?.drink_id.toString() === radioButton.value}
                        onChange={() => {
                            const drink = drinks.find(item => item.drink_id.toString() === radioButton.value);
                            if (drink) setSelectedDrink(drink);
                        }}
                    />
                    <label htmlFor={radioButton.id} style={{ fontSize: '20px', marginLeft: '8px' }}>
                        {radioButton.label}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default UnPublishedDrinksList;
