import { useState } from "react";
import { Button } from "react-bootstrap";
import { Drink } from "../../models/drink";
import { Voter } from "../../models/participant";
import CurrentDrinkItem from "../../components/events/current-drink-item";
import VotingList from "../../components/events/voting-list";
import { VotingModal } from "../../components/events/voting-modal";


type CurrentDrinkProps = {
    currentDrink?: Drink;
    voters: Voter[];
    allowedToPublish: boolean;
    onPublishDrinkClick: () => void;
    onRateDrink: (score: number) => void;
};

const CurrentEventDrinkComponent: React.FC<CurrentDrinkProps> = ({ currentDrink, voters, allowedToPublish, onPublishDrinkClick, onRateDrink }) => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <div>
            {currentDrink && (
                <VotingModal
                    drink={currentDrink.drink_name}
                    isVisible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    onRatingSubmit={(score) => {
                        onRateDrink(score);
                        setModalVisible(false);
                    }}
                />
            )}

            {/* Current Drink Item */}
            {currentDrink && (
                <CurrentDrinkItem drink={currentDrink} onClick={() => setModalVisible(true)} />
            )}

            {currentDrink && (
                <VotingList voters={voters} />
            )}

            {allowedToPublish && (
                <Button style={{ margin: 10 }} onClick={onPublishDrinkClick}>Publicera mina drinkar</Button>
            )}
        </div>
    );
};

export default CurrentEventDrinkComponent;
