import { Drink, DrinkEntity, DrinkInsert, DrinkUpdate } from "../models/drink"
import { supabase } from "../supabase/supabase"
import { ApiResult, APISTATUS, Pagination } from "../types/api"
import { imageService } from "./ImageService"


const DRINK_CREATED_SUCCESS_MESSAGE = "Ny drink skapad!"
const DRINK_UPDATED_SUCCESS_MESSAGE = "Drink uppdaterad!"
const DRINK_DELETED_SUCCESS_MESSAGE = "Drink raderad!"
const DRINK_GET_SUCCESS_MESSAGE = "Alla drinkar hämtade!"
const USER_DRINK_GET_SUCCESS_MESSAGE = "Användarens drinkar hämtade"

const DRINK_CREATED_FAIL_MESSAGE = "Kunde inte skapa ny drink"
const DRINK_UPDATED_FAIL_MESSAGE = "Kunde inte uppdatera drink"
const DRINK_DELETED_FAIL_MESSAGE = "Kunde inte radera drink"
const DRINK_GET_FAIL_MESSAGE = "Kunde inte hämta alla drinkar"
const USER_DRINK_GET_FAIL_MESSAGE = "Kunde inte hämta användarens drinkar"

const DRINK_CREATED_PARTIAL_MESSAGE = "Drink skapad men kunde inte ladda upp bild."
const DRINK_UPDATED_PARTIAL_MESSAGE = undefined
const DRINK_DELETED_PARTIAL_MESSAGE = undefined

const DRINK_PUBLISHED_SUCCESS_MESSAGE = "Drink publicerad!"
const DRINK_PUBLISHED_FAIL_MESSAGE = "Lyckades in publicera drink"

class DrinkService {

    static readonly DRINKS_VIEW = 'drinks_view'


    async getDrinkById(drinkID: number): Promise<ApiResult<Drink | null>> {
        try {
            const { data, error } = await supabase.from(DrinkService.DRINKS_VIEW).select().eq('drink_id', drinkID).maybeSingle()

            if (error) return { status: APISTATUS.FAILURE, message: "Kunde inte hämta drinken" }

            return { status: APISTATUS.SUCCESS, data: data, message: "OK" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Ett fel inträffade när drinken skulle hämtas" }
        }
    }


    async updateDrinkFields(drinkID: number, fields: DrinkUpdate): Promise<ApiResult<DrinkEntity>> {
        try {
            const { data, error } = await supabase.from('drinks').update(fields).eq('drink_id', drinkID).select().single()

            if (error) return { status: APISTATUS.FAILURE, message: DRINK_UPDATED_FAIL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data: data, message: DRINK_UPDATED_SUCCESS_MESSAGE }
        } catch (error) {
            console.error(error)
            return { status: APISTATUS.FAILURE, message: DRINK_UPDATED_FAIL_MESSAGE + ": " + error }
        }
    }


    async updateDrinkIsPublic(drinkID: number, isPublic: boolean): Promise<ApiResult<DrinkEntity>> {
        try {
            const { data, error } = await supabase.from('drinks').update({ public: isPublic }).eq('drink_id', drinkID).select().single()
            if (error) return { status: APISTATUS.FAILURE, message: DRINK_UPDATED_FAIL_MESSAGE }


            return { status: APISTATUS.SUCCESS, data, message: DRINK_PUBLISHED_SUCCESS_MESSAGE }
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINK_UPDATED_FAIL_MESSAGE }
        }
    }

    async getNumberOfDrinks(userID: string): Promise<ApiResult<number>> {
        try {
            const { count, error } = await supabase.from('drinks').select('*', { count: 'exact' }).eq('creator_id', userID)

            if (error) return { status: APISTATUS.FAILURE, message: "Kunde inte hämta antalet drinkar" }

            return { status: APISTATUS.SUCCESS, data: count || 0, message: "OK" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Kunde inte hämta antalet drinkar" }
        }
    }

    async deleteDrink(userID: string, drinkID: number): Promise<ApiResult<null>> {
        try {
            const { data, error } = await supabase.from('drinks').delete().eq('drink_id', drinkID).select('image_url').single()

            if (error) return { status: APISTATUS.FAILURE, message: DRINK_DELETED_FAIL_MESSAGE }

            if (data.image_url === null) {
                return { status: APISTATUS.SUCCESS, data: null, message: DRINK_DELETED_SUCCESS_MESSAGE }
            }

            const res = await imageService.deleteDrinkImage(userID, drinkID)

            if (res.status === APISTATUS.FAILURE) return res

            return { status: APISTATUS.SUCCESS, data: null, message: DRINK_DELETED_SUCCESS_MESSAGE }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINK_DELETED_FAIL_MESSAGE }
        }
    }

    async createNewDrink(userID: string, drinkInsert: DrinkInsert, file?: File): Promise<ApiResult<DrinkEntity>> {
        try {
            const { data: newDrink, error } = await supabase.from('drinks')
                .insert({ ...drinkInsert, creator_id: userID })
                .select()
                .single()

            if (error) return { status: APISTATUS.FAILURE, message: error.message }

            if (!file) return { status: APISTATUS.SUCCESS, data: newDrink, message: DRINK_CREATED_SUCCESS_MESSAGE }

            const upload = await imageService.uploadDrinkImage(newDrink.creator_id, newDrink.drink_id, file)

            if (upload.status === APISTATUS.FAILURE) return { status: APISTATUS.FAILURE, message: DRINK_CREATED_PARTIAL_MESSAGE }

            const { data, error: assignError } = await supabase.from('drinks')
                .update({
                    image_url: upload.data
                })
                .eq('drink_id', newDrink.drink_id)
                .select()

            if (assignError) return { status: APISTATUS.FAILURE, message: assignError.message }

            return { status: APISTATUS.SUCCESS, data: newDrink, message: DRINK_CREATED_SUCCESS_MESSAGE }

        } catch (error) {
            console.error("createNewDrink", error);
            return { status: APISTATUS.FAILURE, message: DRINK_CREATED_FAIL_MESSAGE }
        }
    }

    async updateDrink(userID: string, drinkID: number, drinkUpdate: DrinkUpdate, file?: File): Promise<ApiResult<DrinkEntity>> {
        try {
            let imageUrl = drinkUpdate.image_url
            const newPicture = file !== undefined && (imageUrl === null || imageUrl === undefined)
            const updatePicture = file !== undefined && (imageUrl !== null || imageUrl !== undefined)
            const deletePicture = file === undefined && (imageUrl === null || imageUrl === undefined)


            if (newPicture) {
                const newPic = await imageService.uploadDrinkImage(userID, drinkID, file);

                if (newPic.status === APISTATUS.SUCCESS) {
                    imageUrl = newPic.data
                }
            } else if (updatePicture) {
                const updatePic = await imageService.updateDrinkImage(userID, drinkID, file);

                if (updatePic.status === APISTATUS.SUCCESS) {
                    imageUrl = updatePic.data
                }

            } else if (deletePicture) {
                const { status, message } = await imageService.deleteDrinkImage(userID, drinkID);
                if (status === APISTATUS.SUCCESS) {
                    imageUrl = null
                }
            }

            const updatePayload: DrinkUpdate = {
                ...drinkUpdate, image_url: imageUrl
            };

            const { data, error } = await supabase.from('drinks')
                .update(updatePayload)
                .eq('drink_id', drinkID)
                .select()
                .single()

            if (error) return { status: APISTATUS.FAILURE, message: DRINK_UPDATED_FAIL_MESSAGE };

            return { status: APISTATUS.SUCCESS, data: data, message: DRINK_UPDATED_SUCCESS_MESSAGE };

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINK_UPDATED_FAIL_MESSAGE };
        }
    }


    async getUsersDrinks(userID: string, options?: { byNameDescending: boolean, byCreatedDescending: boolean }): Promise<ApiResult<Drink[]>> {
        try {

            let query = supabase.from(DrinkService.DRINKS_VIEW).select("*").eq('creator_id', userID);

            if (options) {
                query = query.order('drink_name', { ascending: !options.byNameDescending });
                query = query.order('created_at', { ascending: !options.byCreatedDescending });
            }

            const { data, error } = await query;

            if (error) return { status: APISTATUS.FAILURE, message: USER_DRINK_GET_FAIL_MESSAGE };

            return { status: APISTATUS.SUCCESS, data: data, message: USER_DRINK_GET_SUCCESS_MESSAGE };
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: USER_DRINK_GET_FAIL_MESSAGE };
        }
    }



    async getAllDrinks(isPublic: boolean = true, pagination?: Pagination): Promise<ApiResult<Drink[]>> {
        try {

            let query = supabase
                .from(DrinkService.DRINKS_VIEW)
                .select("*")
                .is('public', isPublic)
                .order('created_at', { ascending: false })

            if (pagination) query = query.range(pagination.start, pagination.end);

            const { data: drinks, error } = await query;


            if (error) return { status: APISTATUS.FAILURE, message: DRINK_GET_FAIL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data: drinks, message: DRINK_GET_SUCCESS_MESSAGE };
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINK_GET_FAIL_MESSAGE };
        }
    }
    
    async searchDrinks(searchTerms: string[], ingredientNames?: string[], users?: string[], filters?: string[], pagination?: Pagination): Promise<ApiResult<Drink[]>> {
        try {

            let query = supabase
                .rpc('search_drinks', {
                    keywords: searchTerms,
                    user_ids: users ?? []
                })
                .order('created_at', { ascending: false })

                

            if (pagination) query = query.range(pagination.start, pagination.end);

            const { data: drinks, error } = await query;

    

            if (error) return { status: APISTATUS.FAILURE, message: "Kunde inte söka på drinkarna" }


            return { status: APISTATUS.SUCCESS, data: drinks ?? [], message: "OK" }
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed when searching for drinks" }
        }
    }

    async getAllDrinksFromEvent(eventID: string, isPublic?: boolean | undefined): Promise<ApiResult<Drink[]>> {
        try {
            const { data, error } = await supabase.from(DrinkService.DRINKS_VIEW).select('*')
                .eq('event_id', eventID)
                .eq('public', isPublic || true)


            // TODO: annat meddelande?
            if (error) return { status: APISTATUS.FAILURE, message: DRINK_GET_FAIL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data: data, message: DRINK_GET_SUCCESS_MESSAGE }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINK_GET_FAIL_MESSAGE + " från event " + eventID }
        }
    }

    async publishDrinkToEvent(drinkID: number, eventID: number): Promise<ApiResult<null>> {
        try {
            const currentDateTime = new Date().toISOString();

            const { data, error: publishError } = await supabase.from('events')
                .update({ current_drink_id: drinkID }).eq('id', eventID).select().single()


            if (publishError) return { status: APISTATUS.FAILURE, message: DRINK_PUBLISHED_FAIL_MESSAGE + " till event " + eventID }

            const { error: drinkError } = await supabase.from("drinks")
                .update({ event_id: eventID, public: true, published_at: currentDateTime })
                .eq('drink_id', drinkID)


            if (drinkError) return { status: APISTATUS.FAILURE, message: "Lyckades publicera till event men inte uppdatera drink " + drinkID }



            return { status: APISTATUS.SUCCESS, data: null, message: DRINK_PUBLISHED_SUCCESS_MESSAGE }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINK_PUBLISHED_FAIL_MESSAGE + " till event " + eventID }

        }
    }

    async getAllUnPublishedDrinksFromEvent(userID: string): Promise<ApiResult<Drink[]>> {
        try {
            const { data, error } = await supabase.from(DrinkService.DRINKS_VIEW).select('*')
                .eq('creator_id', userID)
                .eq('public', false)


            // TODO: Annat meddelande?
            if (error) return { status: APISTATUS.FAILURE, message: DRINK_GET_FAIL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data, message: DRINK_GET_SUCCESS_MESSAGE }


        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINK_GET_FAIL_MESSAGE + "från evented" }

        }
    }

}

function createDrinkService(): DrinkService {
    return new DrinkService()
}

export const drinkService = createDrinkService()