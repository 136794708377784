import React from 'react';
import { Outlet } from 'react-router-dom';
import { ResponsiveNavBar } from './components/navbars/responsive-navbar';


const App: React.FC = () => {
  return (
    <div className="App">
      <ResponsiveNavBar />
      <Outlet />
    </div>
  );
};

export default App;
