import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './css/current-event-scoreboard.css';
import { ScoreboardItem } from '../../models/drink-score';



type ScoreboardProps = {
    scores: ScoreboardItem[];
};

const CurrentEventScoreboard: React.FC<ScoreboardProps> = ({ scores }) => {
    const generateGradientColor = (index: number, length: number): string => {
        // Function to generate gradient colors, you can customize it
        const hue = (index / length) * 360;
        return `hsl(${hue}, 70%, 50%)`;
    };

    return (
        <Container className="event-scoreboard-container">
            <h2 className="scoreboard-title">Scoreboard</h2>
            {scores.map((drink, index) => {
                const color = generateGradientColor(index, scores.length);
                return (
                    <ScoreboardRowItem
                        key={drink.drink_id}
                        rank={index + 1}
                        item={drink}
                        backgroundColor={index === 0 ? 'palevioletred' : color}
                    />
                );
            })}
        </Container>
    );
};

export default CurrentEventScoreboard;

type ScoreboardRowItemProps = {
    rank: number;
    item: ScoreboardItem;
    backgroundColor: string;
};

const ScoreboardRowItem: React.FC<ScoreboardRowItemProps> = ({ rank, item, backgroundColor }) => {
    return (
        <Row className="scoreboard-row" style={{ backgroundColor: backgroundColor }}>
            <Col xs={1} className="rank-container">
                <span className="rank-text">{rank}</span>
            </Col>
            <Col xs={2} className="image-container">
                {item.drink_image_url ? (
                    <img src={item.drink_image_url} alt="Drink" className="drink-image" />
                ) : (
                    <div className="drink-placeholder">No Image</div>
                )}
            </Col>
            <Col xs={6} className="name-container">
                <span className="drink-text">{item.drink_name}</span>
                <span className="creator-text">- {item.creator_full_name}</span>
            </Col>
            <Col xs={3} className="score-container">
                <i className="fa fa-star" style={{ color: 'orange', fontSize: '24px' }}></i>
                <span className="score-text">{item.avg_score}</span>
            </Col>
        </Row>
    );
};
