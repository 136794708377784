import { FaEye, FaEyeSlash } from "react-icons/fa";
import { DrinkWithMedal } from "../../models/drink";
import styles from '../profile/css/my-drink-item.module.css';
import { Button } from "semantic-ui-react";

type Props = {
    drink: DrinkWithMedal;
    onSetPublic?: () => void;
    onAssignToEvent?: () => void;
    onDrinkSelect?: () => void;
}

export const MyDrinkItem: React.FC<Props> = ({ drink, onSetPublic, onAssignToEvent, onDrinkSelect }) => {
    const handleSetPublicClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (onSetPublic) {
            onSetPublic();
        }
    };

    return (
        <div className={styles.container} onClick={onDrinkSelect}>
            <div className={styles.imageContainer}>
                {drink.drink_image_url ? (
                    <img src={drink.drink_image_url} alt={drink.drink_name} className={styles.drinkImage} />
                ) : (
                    <div className={styles.drinkImage}>Placeholder</div> // Use a placeholder if no image
                )}
            </div>
            <div className={styles.titleContainer}>
                <p className={styles.drinkTitle}>{drink.drink_name}</p>
            </div>
            <div className={styles.iconContainer}>
                {drink.medal && (
                    <div className={styles.icon}>Medal</div> // Replace with your SimpleBadge component
                )}
                <div className={styles.icon}>
                    <Button icon onClick={handleSetPublicClick}>
                        {drink.public ? <FaEye color="green" /> : <FaEyeSlash color="red" />}
                    </Button>
                </div>
            </div>
        </div>
    );
};
