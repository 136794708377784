import React from 'react';
import useDeviceType from '../hooks/useDeviceType'; // Adjust the import path as needed
import styles from '../../css/my-drink-feed.module.css'; // Adjust the import path as needed
import { Drink, DrinkWithMedal } from '../../models/drink';
import { MyDrinkItem } from './my-drink-item';
import MyDrinkCard from './my-drink-card';


type Props = {
    drinks: DrinkWithMedal[];
    onSetPublic: (drink: Drink) => void;
    onDrinkSelect: (drink: Drink) => void;
};

const MyDrinkFeed: React.FC<Props> = ({ drinks, onSetPublic, onDrinkSelect }) => {
    const deviceType = useDeviceType()

    return (
        <div>
            {deviceType === 'phone' ? (
                drinks.map(drink => (
                    <MyDrinkItem
                        key={drink.drink_id}
                        drink={drink}
                        onSetPublic={() => onSetPublic(drink)}
                        onDrinkSelect={() => onDrinkSelect && onDrinkSelect(drink)}
                    />
                ))
            ) : (
                <div className={styles.drinkList}>
                    {drinks.map(drink => (
                        <MyDrinkCard
                            key={drink.drink_id}
                            drink={drink}
                            onSetPublic={() => onSetPublic(drink)}
                            onDrinkSelect={() => onDrinkSelect(drink)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default MyDrinkFeed;
