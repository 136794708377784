import React, { useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

export const ImageCropper = ({ showModal, onModalClose, imgURL, onSaveHandler, fileName }) => {
    const cropperRef = useRef(null);

    const onCrop = () => {
        const cropper = cropperRef.current;
        if (cropper) {
            const canvas = cropper.getCanvas();
            if (canvas) {
                return canvas.toDataURL('image/jpeg');
            }
        }
        return null;
    };

    const handleSave = async () => {
        const croppedImage = onCrop();
        if (croppedImage) {
            const res = await fetch(croppedImage);
            const blob = await res.blob();
            const imageFile = new File([blob], fileName, { type: "image/jpeg" });
            onSaveHandler(croppedImage, imageFile);
        }
        onModalClose();
    };

    return (
        <Modal
            show={showModal}
            onHide={onModalClose}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Redigera bilden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {imgURL && (
                    <div className="cropper-container">
                        <Cropper
                            ref={cropperRef}
                            src={imgURL}      
                            style={{ width: '100%', height: '400px' }}
                        />
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onModalClose}>
                    Avbryt
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Spara redigering
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
