import { useEffect, useState } from "react";

type DeviceType = 'phone' | 'tablet' | 'desktop';



const getDeviceType = (): DeviceType => {
    if (typeof window !== 'undefined') {
        if (window.matchMedia('(max-width: 767px)').matches) {
            return 'phone';
        } else if (window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches) {
            return 'tablet';
        } else {
            return 'desktop';
        }
    }
    return 'desktop';
};

const useDeviceType = (): DeviceType => {
    const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType());

    useEffect(() => {
        const handleResize = () => {
            setDeviceType(getDeviceType());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return deviceType;
};

export default useDeviceType;