import React from 'react';
import { useAuth } from '../../context/auth-context';
import ProfileBanner from '../../components/profile/profile-banner';
import { useUser } from '../../context/user-context';
import MyDrinkFeed from '../../components/profile/my-drink-feed';
import { Drink } from '../../models/drink';
import "./css/profile-page.css";
import { Button, Col, Container, Row } from 'react-bootstrap';
import { CiEdit } from 'react-icons/ci';
import { FaPlus } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const ProfilePage: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { userDrinks, medalCollection, toggleDrinkIsPublic } = useUser();

    const handleTogglePublic = async (drink: Drink) => {
        await toggleDrinkIsPublic(drink);
    };

    const handleEditProfile = () => {
        navigate("/profile/edit");
    };


    const handleAddDrink = () => {
        navigate("/profile/drinks/add");

    };

    const handleOnDrinkSelect = (drink: Drink) => {
        navigate(`/profile/drinks/${drink.drink_id}/edit`, { state: { drink } });
    };

    return (
        <div className="page">
            <div className="content">
                <ProfileBanner profile={user} medalCollection={medalCollection} borderRadius={10} avatar_height={100} />
                <Container>
                    <Row className="mb-3">
                        <Col>
                            <Button className="w-100" size="lg" onClick={handleAddDrink}>
                                <div className="button-content">
                                    <FaPlus />
                                    Lägg till drink
                                </div>
                            </Button>
                        </Col>
                        <Col>
                            <Button className="w-100" size="lg" onClick={handleEditProfile} variant='secondary'>
                                <div className="button-content">
                                    <CiEdit />
                                    Redigera profil
                                </div>
                            </Button>
                        </Col>

                    </Row>
                </Container>
                <MyDrinkFeed drinks={userDrinks} onSetPublic={handleTogglePublic} onDrinkSelect={handleOnDrinkSelect} />
            </div>
        </div>
    );
}

export default ProfilePage;
