import React, { useState } from "react";
import styles from "../../css/drink-card.module.css"; // Adjust the import path as needed
import { Drink } from "../../models/drink";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface DrinkCardProps {
    drink: Drink;
    onDrinkSelect: (drink: Drink) => void;
    onSetPublic?: () => void;
    showProfile?: boolean;
    onlyFirstName?: boolean;
}

const MyDrinkCard: React.FC<DrinkCardProps> = ({ drink, onDrinkSelect, showProfile, onSetPublic }) => {
    const [imageHeight, setImageHeight] = useState<number>(0);

    const onImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        const { height } = event.currentTarget;
        setImageHeight(height);
    };

    const fontSize = Math.max(20, imageHeight * 0.06);

    return (
        <div className={styles.cardContainer}>
            <div className={styles.imageContainer} onClick={() => onDrinkSelect(drink)}>
                {showProfile && drink.creator_avatar_url && (
                    <img src={drink.creator_avatar_url} alt="Creator Avatar" className={styles.avatarImage} />
                )}

                {drink.drink_image_url ? (
                    <img className={styles.drinkImage} src={drink.drink_image_url} alt="Drink" onLoad={onImageLoad} />
                ) : (
                    <div className={styles.placeholderIconContainer}>
                        <div className={styles.placeholderIcon}>🍹</div>
                    </div>
                )}

                <div className={styles.titleOverlay} style={{ fontSize }}>
                    <div className={styles.drinkTitle}>{drink.drink_name}</div>
                </div>
            </div>
            <div className={styles.iconContainer}>
                <div className={styles.icon} onClick={onSetPublic}>
                    {drink.public ? <FaEye color="green" /> : <FaEyeSlash color="red" />}
                </div>
            </div>
        </div>
    );
};

export default MyDrinkCard;
