import { Container, Col } from "react-bootstrap";
import { welcomeText } from "../resources/welcomeText";
import "../css/home-page.css";

export const HomePage = () => {
    return (
        <div className="frontpage-container"> 
        <Container  className="py-5 d-flex justify-content-center">
        
            <Col xs={11} sm={12} md={12} lg={6} className="maintext">
                <h1 className="htext">{welcomeText.welcome}</h1>
                <p className="ptext">{welcomeText.introduction}</p>
                <a 
                href="https://www.freepik.com/free-photo/top-view-frame-with-drink-glass-copy-space_6848332.htm#query=drinks%20on%20table&position=0&from_view=search&track=ais" 
                className="attribute-link">Image by Freepik</a>
            </Col>

        </Container>
       
    </div>
    );
}