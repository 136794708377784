import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import ErrorPage from './pages/error-page';
import { AuthProvider } from './context/auth-context';
import DrinkPage from './pages/drinks/drink-page';
import EventPage from './pages/event/event-page';
import ProfilePage from './pages/profile/profile-page';

import { DrinkDetailPage } from './pages/drinks/drink-detail-page';
import { DrinkProvider } from './context/drink-context';
import { drinkLoader } from './loaders/drink-loader';
import { UserProvider } from './context/user-context';
import { HomePage } from './pages/home-page';
import { ProtectedRoute } from './utils';
import { EventProvider } from './context/event-context';
import { EventDetailPage } from './pages/event/event-detail-page';
import { CurrentEventProvider } from './context/current-event-context';
import { eventLoader } from './loaders/event-loader';
import PublishDrinkPage from './pages/event/publish-drink-page';
import { EditDrinkPage } from './pages/profile/edit-drink-page';
import { EditProfilePage } from './pages/profile/edit-profile-page';
import { AddDrinkPage } from './pages/profile/add-drink-page';



const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <HomePage />
            },
            {
                path: "drinks",
                element: (
                    <DrinkProvider>
                        <DrinkPage />
                    </DrinkProvider>
                ),

            },
            {
                path: "drinks/:drinkID",
                loader: drinkLoader,
                element: (
                    <DrinkProvider>
                        <DrinkDetailPage />
                    </DrinkProvider>
                ),

            },
            {
                path: "events",
                element: (
                    <ProtectedRoute>
                        <EventProvider>
                            <EventPage />
                        </EventProvider>
                    </ProtectedRoute>
                )

            },
            {
                path: "events/:eventID",
                loader: eventLoader,
                element: (
                    <ProtectedRoute>
                        <EventProvider>
                            <CurrentEventProvider>
                                <EventDetailPage />
                            </CurrentEventProvider>
                        </EventProvider>
                    </ProtectedRoute>


                )
            },
            {
                path: "events/:eventID/publishdrink",
                element: (
                    <ProtectedRoute>
                        <EventProvider>
                            <CurrentEventProvider>
                                <PublishDrinkPage />
                            </CurrentEventProvider>
                        </EventProvider>
                    </ProtectedRoute>


                )
            },
            {

                path: "profile",
                element: (
                    <UserProvider>
                        <ProtectedRoute>
                            <ProfilePage />
                        </ProtectedRoute>
                    </UserProvider>
                )

            },
            {

                path: "profile/edit",
                element: (
                    <UserProvider>
                        <ProtectedRoute>
                            <EditProfilePage />
                        </ProtectedRoute>
                    </UserProvider>
                )

            },
            {

                path: "profile/drinks/add",
                element: (
                    <UserProvider>
                        <ProtectedRoute>
                            <AddDrinkPage />
                        </ProtectedRoute>
                    </UserProvider>
                )

            },
            {
                path: "profile/drinks/:drinkID/edit",
                element: (
                    <UserProvider>
                        <ProtectedRoute>
                            <EditDrinkPage />
                        </ProtectedRoute>
                    </UserProvider>
                )

            }
        ]
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AuthProvider>
            <UserProvider>
                <RouterProvider router={router} />
            </UserProvider>
        </AuthProvider>
    </React.StrictMode>
);
