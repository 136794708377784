import { RealtimePostgresInsertPayload, RealtimePostgresDeletePayload, RealtimePostgresUpdatePayload, RealtimePostgresChangesPayload } from "@supabase/supabase-js"
import { ReactNode, createContext, FunctionComponent, useState, useMemo, useEffect, useContext } from "react"
import { Drink } from "../models/drink"
import { ScoreboardItem, EventDrinkscoreRow } from "../models/drink-score"
import { Participant, Voter } from "../models/participant"
import { drinkService } from "../services/DrinkService"
import { drinkscoreService } from "../services/DrinkscoreService"
import { eventService } from "../services/EventService"
import { ApiResult, APISTATUS } from "../types/api"
import { useAuth } from "./auth-context"
import { useEvent } from "./event-context"
import { ParticipantEntity, EventEntity } from "../models/event"
import { User } from "../models/user"
import { userService } from "../services/UserService"


interface CurrentEventContextType {
    allParticipants: Participant[]
    drinkScores: ScoreboardItem[]
    unPublishedDrinks: Drink[]
    selectedDrink?: Drink
    currentDrink?: Drink
    canPublishNewDrink: boolean
    allDrinkScoresForCurrentDrink: EventDrinkscoreRow[]
    voters: Voter[]
    isOwner: boolean
    isParticipant: boolean
    loadingContent: boolean
    loadingProcess: boolean
    refreshUsersUnpublishedDrinks: () => Promise<void>
    publishDrink: () => Promise<ApiResult<null>>
    selectDrinkForPublish: (drink: Drink) => void
    removePublishedDrinkFromEvent: () => Promise<void>
    joinEvent: (eventID: number) => Promise<ApiResult<null>>
    leaveEvent: (eventID: number) => Promise<ApiResult<null>>
    rateCurrentDrink: (score: number) => Promise<void>
    listenForJoiners: (payload: RealtimePostgresInsertPayload<ParticipantEntity>) => void
    listenForLeavers: (payload: RealtimePostgresDeletePayload<ParticipantEntity>) => void
    listenForNewPublishedDrinks: (payload: RealtimePostgresUpdatePayload<EventEntity>) => Promise<void>
    listenForScoreUpdates: (payload: RealtimePostgresChangesPayload<EventDrinkscoreRow>) => Promise<void>
}

interface CurrentEventProviderProps {
    children: ReactNode;
}

const CurrentEventContext = createContext<CurrentEventContextType | undefined>(undefined);


export const CurrentEventProvider: FunctionComponent<CurrentEventProviderProps> = ({ children }) => {
    const { selectedDrinkEvent } = useEvent()
    const { session, user } = useAuth()

    const [loadingContent, setLoadingContent] = useState(true)
    const [loadingProcess, setLoadingProcess] = useState<boolean>(false);
    const [allParticipants, setAllParticipants] = useState<Participant[]>([])
    const [currentDrink, setCurrentDrink] = useState<Drink>()
    const [allDrinkScoresForCurrentDrink, setAllDrinkScoresForCurrentDrink] = useState<EventDrinkscoreRow[]>([])
    const [drinkScores, setDrinkScores] = useState<ScoreboardItem[]>([])

    const isOwner = (selectedDrinkEvent && session) ? selectedDrinkEvent.creator_id === session.user.id : false
    const isParticipant = allParticipants.some(item => item.user_id === session?.user.id)

    const voters: Voter[] = useMemo(() => {
        if (!currentDrink) return []

        return allParticipants.map(participant => {
            const hasVoted = allDrinkScoresForCurrentDrink.some(score => participant.user_id === score.voter_id);
            return {
                user_id: participant.user_id,
                full_name: participant.full_name,
                voted: hasVoted,
            };
        });
    }, [currentDrink, allParticipants, allDrinkScoresForCurrentDrink]);

    const canPublishNewDrink = !currentDrink || allDrinkScoresForCurrentDrink.length === allParticipants.length

    const [unPublishedDrinks, setUnPublishedDrinks] = useState<Drink[]>([])
    const [selectedDrink, setSelectedDrink] = useState<Drink>()



    useEffect(() => {
        async function fetchData(user: User, eventID: number) {
            setLoadingContent(true)
            const getParticipants = await eventService.getParticipantsForEvent(eventID);
            if (getParticipants.status === APISTATUS.SUCCESS) setAllParticipants(getParticipants.data)

            const getCurrentDrink = await eventService.getCurrentPublishedDrink(eventID)
            if (getCurrentDrink.status === APISTATUS.SUCCESS && getCurrentDrink.data) {
                setCurrentDrink(getCurrentDrink.data)
            }

            if (selectedDrinkEvent?.current_drink_id) {
                const getScoresForCurrentDrink =
                    await drinkscoreService.getEventDrinkscoresForDrink(selectedDrinkEvent.event_id, selectedDrinkEvent.current_drink_id)
                if (getScoresForCurrentDrink.status === APISTATUS.SUCCESS) {
                    setAllDrinkScoresForCurrentDrink(getScoresForCurrentDrink.data)
                }

            }



            const getDrinkScore = await drinkscoreService.getHighscoreForEvent(eventID)
       
            
            if (getDrinkScore.status === APISTATUS.SUCCESS) {
                setDrinkScores(getDrinkScore.data)
            }


            const getUsersUnpublishedDrink = await drinkService.getAllUnPublishedDrinksFromEvent(user.id)
            if (getUsersUnpublishedDrink.status === APISTATUS.SUCCESS) {
                setUnPublishedDrinks(getUsersUnpublishedDrink.data)
            }

        }

        if (selectedDrinkEvent && user) {
            setLoadingContent(true)
            fetchData(user, selectedDrinkEvent.event_id);
            setLoadingContent(false)
        }


    }, [selectedDrinkEvent, session])

    const publishDrink = async (): Promise<ApiResult<null>> => {
        setLoadingProcess(true)
        setLoadingContent(true)
        if (!selectedDrink || !selectedDrinkEvent) {
            setLoadingProcess(false)
            setLoadingContent(false)
            return { status: APISTATUS.FAILURE, message: "Du måste vara inloggad" }
        }

        const result = await drinkService.publishDrinkToEvent(selectedDrink.drink_id, selectedDrinkEvent.event_id)
        setUnPublishedDrinks(prev => prev.filter(drink => drink.drink_id !== selectedDrink.drink_id))
        setSelectedDrink(undefined)
        setLoadingProcess(false)
        setLoadingContent(false)



        return result
    }

    const refreshUsersUnpublishedDrinks = async () => {
        if (!user) return

        const getUsersUnpublishedDrink = await drinkService.getAllUnPublishedDrinksFromEvent(user.id)
        if (getUsersUnpublishedDrink.status === APISTATUS.SUCCESS) {
            setUnPublishedDrinks(getUsersUnpublishedDrink.data)
        }

    }

    const selectDrinkForPublish = (drink: Drink) => {
        setSelectedDrink(drink)
    }

    const rateCurrentDrink = async (score: number) => {
        setLoadingProcess(true)
        if (!(session && selectedDrinkEvent && currentDrink)) {
            setLoadingProcess(false)
            return
        }
        const res = await drinkscoreService.rateDrinkInEvent(session.user.id, currentDrink.drink_id, selectedDrinkEvent.event_id, score)
        setLoadingProcess(false)
        
    }

    const joinEvent = async (eventID: number): Promise<ApiResult<null>> => {
        setLoadingProcess(true)
        if (!session || !user) {
            setLoadingProcess(false)
            return { status: APISTATUS.FAILURE, message: "Du måste vara inloggad" }
        }
        const res = await eventService.joinEvent(session.user.id, eventID)

        if (res.status === APISTATUS.SUCCESS) {
            const addParticipant: Participant = { event_id: eventID, event_status: "pending", user_id: session.user.id, full_name: user.full_name }
            setAllParticipants(prev => [...prev, addParticipant])
        }
        setLoadingProcess(false)
        return res

    };

    const leaveEvent = async (eventID: number): Promise<ApiResult<null>> => {
        setLoadingProcess(true)
        if (!session) {
            setLoadingProcess(false)
            return { status: APISTATUS.FAILURE, message: "Du måste vara inloggad" }
        }

        const res = await eventService.leaveEvent(session.user.id, eventID)
        if (res.status === APISTATUS.SUCCESS) {
            setAllParticipants(prev => prev.filter(user => user.user_id !== session.user.id))
        }
        setLoadingProcess(false)
        return res
    };

    const listenForJoiners = async (payload: RealtimePostgresInsertPayload<ParticipantEntity>) => {
        const isMe = payload.new.participant_id === user?.id
        if (isMe) return

        const res = await userService.getUser(payload.new.participant_id)

        if (res.status === APISTATUS.SUCCESS) {
            const addParticipant: Participant = {
                event_id: payload.new.event_id,
                event_status: "pending",
                user_id: res.data.id,
                full_name: res.data.full_name
            }
            setAllParticipants(prev => [...prev, addParticipant])
        }
    }


    const listenForLeavers = async (payload: RealtimePostgresDeletePayload<ParticipantEntity>) => {
        const isMe = payload.old.participant_id === user?.id
        if (isMe) return

        if (!payload.old.participant_id) return
        setAllParticipants(prev => prev.filter(user => user.user_id !== payload.old.participant_id))
    }

    const listenForNewPublishedDrinks = async (payload: RealtimePostgresUpdatePayload<EventEntity>) => {
        const { current_drink_id, id: eventID } = payload.new
        if (!current_drink_id || !eventID) return

        const res = await drinkService.getDrinkById(current_drink_id)

        if (res.status === APISTATUS.SUCCESS) {
            setCurrentDrink(res.data ?? undefined)
        }

        const getScoreForCurrentDrink = await drinkscoreService.getEventDrinkscoresForDrink(eventID, current_drink_id)
        if (getScoreForCurrentDrink.status === APISTATUS.SUCCESS) {
            setAllDrinkScoresForCurrentDrink(getScoreForCurrentDrink.data)
        }
    }

    const listenForScoreUpdates = async (payload: RealtimePostgresChangesPayload<EventDrinkscoreRow>) => {


        if (payload.eventType === "INSERT") {
            setAllDrinkScoresForCurrentDrink(prev => [...prev, payload.new]);

            if (!selectedDrinkEvent) return
            const getDrinkScore = await drinkscoreService.getHighscoreForEvent(selectedDrinkEvent.event_id)
      
            if (getDrinkScore.status === APISTATUS.SUCCESS) {
                setDrinkScores(getDrinkScore.data)
            }


        }

        if (payload.eventType === "UPDATE") {
            setAllDrinkScoresForCurrentDrink(prevScores => {
                const scoreIndex = prevScores.findIndex(score => score.drink_id === payload.new.drink_id);

                if (scoreIndex !== -1) {
                    const updatedDrinks = [...prevScores];
                    updatedDrinks[scoreIndex].score = payload.new.score;
                    return updatedDrinks;
                }
                return prevScores;
            });


            if (!selectedDrinkEvent) return
            const getDrinkScore = await drinkscoreService.getHighscoreForEvent(selectedDrinkEvent.event_id)
            if (getDrinkScore.status === APISTATUS.SUCCESS) {
                setDrinkScores(getDrinkScore.data)
            }
        }


        // For test purpose
        if (payload.eventType === 'DELETE') {
            if (payload.old) {
                setAllDrinkScoresForCurrentDrink(prev => prev.filter(item => item.voter_id !== payload.old.voter_id));
            }

        }
    }

    // For testing
    const removePublishedDrinkFromEvent = async () => {
        if (!selectedDrinkEvent) return
        const res = await eventService.removePublishedDrinkFromEvent(selectedDrinkEvent.event_id)
        if (res.status === APISTATUS.SUCCESS) {
            setCurrentDrink(undefined)
        }
    }

    const value = {
        allParticipants,
        canPublishNewDrink,
        voters,
        drinkScores,
        currentDrink,
        allDrinkScoresForCurrentDrink,
        isOwner,
        isParticipant,
        unPublishedDrinks,
        selectedDrink,
        loadingProcess,
        loadingContent,
        refreshUsersUnpublishedDrinks,
        publishDrink,
        selectDrinkForPublish,
        removePublishedDrinkFromEvent,
        rateCurrentDrink,
        joinEvent,
        leaveEvent,
        listenForJoiners,
        listenForLeavers,
        listenForNewPublishedDrinks,
        listenForScoreUpdates
    }

    return (
        <CurrentEventContext.Provider value={value}>
            {children}
        </CurrentEventContext.Provider>
    )
};


export const useCurrentEvent = (): CurrentEventContextType => {
    const context = useContext(CurrentEventContext);
    if (context === undefined) {
        throw new Error('useCurrentEvent must be used within a CurrentEventProvider');
    }
    return context;
};
