import React, { useRef, useState } from 'react';
import { Button, Container, Form, Alert } from "react-bootstrap";
import { useUser } from "../../context/user-context";
import { FaCamera, FaUserCircle } from 'react-icons/fa';
import LinearProgress from '@mui/material/LinearProgress';
import "./css/edit-profile-page.css";
import { APISTATUS } from '../../types/api';
import { ImageCropper } from '../../components/modals/image-cropper';
import PasswordModal from '../../components/profile/password-moda';


export const EditProfilePage: React.FC = () => {
    const { user, updateUser, loadingOperation } = useUser();
    const [username, setUsername] = useState(user.full_name);
    const [preview, setPreview] = useState<string | undefined>(user.avatar_url);
    const [imageFile, setImageFile] = useState<File>()
    const [wantsToRemoveProfile, setWantsToRemoveProfile] = useState(false)
    const [error, setError] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false)
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [passwordModal, setPasswordModal] = useState(false)

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
                setShowModal(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const resetChanges = () => {
        setPreview(user.avatar_url);
        setUsername(user.full_name);
        setWantsToRemoveProfile(false)
        setError(null);
    };

    const handleOnCropSaved = (croppedImage: string, imageFile: File) => {
        setPreview(croppedImage);
        setImageFile(imageFile)
        setShowModal(false);
    };

    const handleSave = async () => {
        if (username.trim() === "") {
            setError("Namn kan inte vara tomt.");
            return;
        }

        setError(null);
        setWantsToRemoveProfile(false);

        const updatedUser = { ...user, full_name: username };
       

        const res = await updateUser(updatedUser, imageFile, wantsToRemoveProfile);

        if (res.status === APISTATUS.SUCCESS) {
            setWantsToRemoveProfile(false)
            setError(null);
        } else {
            setError(res.message);
        }
    };

    const handleRemoveImage = () => {
        setWantsToRemoveProfile(true);
        setPreview(undefined);
    };

    const isChanged = username !== user.full_name || preview !== user.avatar_url;

    return (
        <div className="edit-profile-page">
            <div className="edit-profile-content">
                {loadingOperation && (
                    <LinearProgress color='success' />
                )}

                <div className="edit-profile-image-container" onClick={handleImageClick}>
                    {preview ? (
                        <img src={preview} alt="Profile Avatar" className="edit-profile-image" />
                    ) : wantsToRemoveProfile ? (
                        <div className="edit-profile-image-placeholder">
                            <FaCamera />
                        </div>
                    ) : (
                        <div className="edit-profile-image-placeholder">
                            <FaUserCircle />
                        </div>
                    )}
                    <div className="edit-profile-icon-overlay">
                        <FaCamera />
                    </div>
                </div>
                <input type="file" ref={fileInputRef} className="edit-profile-hidden-file-input" onChange={handleImageChange} />
                <Form>
                    <Form.Group className="m-5" controlId="formBasicEmail">
                        <Form.Label>Namn</Form.Label>
                        <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                    </Form.Group>
                </Form>
                {error && <Alert variant="danger" className="m-5">{error}</Alert>}
                <Container className="edit-profile-button-content">
                    <ImageCropper
                        showModal={showModal}
                        imgURL={preview}
                        onModalClose={() => setShowModal(false)}
                        onSaveHandler={handleOnCropSaved}
                        fileName={"profile.jpg"}
                         />
                    {user.avatar_url && (
                        <Button variant="danger" onClick={handleRemoveImage}>
                            Radera profilbild
                        </Button>
                    )}
                    <Button variant="secondary" onClick={resetChanges} disabled={!isChanged}>
                        Återställ
                    </Button>
                    <Button className='save-btn' variant='success' onClick={() => handleSave()} disabled={!isChanged}>
                        Spara
                    </Button>
                </Container>
            </div>

            <div className="edit-profile-content mt-3 justify-content-end">
                <Button onClick={() => setPasswordModal(true)}>Byt lösenord</Button>
            </div>

            <PasswordModal show={passwordModal} handleClose={() => setPasswordModal(false)} />
        </div>
    );
};

export default EditProfilePage;
