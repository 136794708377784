import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './css/voting-modal.css';
import { Rating, RatingProps } from 'semantic-ui-react';

interface VotingModalProps {
    drink: string;
    isVisible: boolean;
    onClose?: () => void;
    onRatingSubmit: (rating: number) => void;
}

export const VotingModal: React.FC<VotingModalProps> = ({ drink, isVisible, onClose, onRatingSubmit }) => {
    const [rating, setRating] = useState<number>(5);

    const handleOnRating = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: RatingProps) => {
        if(data.rating)
            setRating(Number(data.rating));
            
    
    };

    const handleSubmit = () => {
        onRatingSubmit(rating);
    };

    return (
        <Modal show={isVisible} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Rösta på {drink}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Rating
                    min={1}
                    maxRating={10}
                    defaultRating={rating}
                    value={rating}
                    size="large"
                    onRate={handleOnRating}
                    
                    activeColor="#ffd700"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Stäng
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Rösta! ({rating})
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

