import useDeviceType from "../hooks/useDeviceType";
import { useAuth } from "../../context/auth-context";

import OffcanvasNavbar from './offcanvas-navbar';
import NormalNavbar from './normal-navbar';
import { useUser } from "../../context/user-context";


export const ResponsiveNavBar = () => {
    const deviceType = useDeviceType();
    const { session, logout } = useAuth();
    const { user } = useUser()

    if (deviceType === 'desktop' || deviceType === 'tablet') {
        return <NormalNavbar user={user} loggedIn={!!session} logout={logout} />;
    } else {
        return <OffcanvasNavbar user={user} loggedIn={!!session} logout={logout} />;
    }
};
