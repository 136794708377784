import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useAuth } from "../../context/auth-context";
import { APISTATUS } from '../../types/api';

type PasswordModalProps = {
    show: boolean;
    handleClose: () => void;
};

export const PasswordModal: React.FC<PasswordModalProps> = ({ show, handleClose }) => {
    const { updatePassword } = useAuth();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        if (newPassword !== confirmPassword) {
            setError('Nya lösenordet och bekräftelsen stämmer inte överens.');
            return;
        }

        setError(null);
        setLoading(true);

        const res = await updatePassword(newPassword);

        setLoading(false);

        if (res.status === APISTATUS.SUCCESS) {
            setSuccess(res.message);
        } else {
            setError(res.message);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Byt lösenord</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}
                <Form>
                    <Form.Group controlId="formNewPassword" className="mt-3">
                        <Form.Label>Nytt lösenord</Form.Label>
                        <Form.Control
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group controlId="formConfirmPassword" className="mt-3">
                        <Form.Label>Bekräfta nytt lösenord</Form.Label>
                        <Form.Control
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={loading}>
                    Avbryt
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={loading}>
                    Spara
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PasswordModal;
