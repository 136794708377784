import React, { useState } from 'react';
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "./normal-navbar.css";
import LoginModal from "../modals/login-modal";
import { User } from "../../models/user";

type Props = {
    user: User;
    loggedIn: boolean;
    logout: () => void;
};

const NormalNavbar = ({ user, loggedIn, logout }: Props) => {
    const [showLoginModal, setShowLoginModal] = useState(false);

    const handleShowLogin = () => setShowLoginModal(true);
    const handleCloseLogin = () => setShowLoginModal(false);

    return (
        <>
            <Navbar bg="dark" variant="dark" sticky="top" className="normal-navbar">
                <Container className="navbar-container">
                    <div className="normal-brand-container">
                        <img className="normal-brand-image" src="/logo512.png" alt="Logo" />
                        <Navbar.Brand className="normal-navbar-brand" as={Link} to="/">Drink Drink</Navbar.Brand>
                    </div>
                    <Nav className="normal-nav-links">
                        <NavLink className='normal-nav-link' to="/drinks">Drinkar</NavLink>
                        {loggedIn ? (
                            <>
                                <NavLink className='normal-nav-link' to="/events">Event</NavLink>
                                <NavLink className='normal-nav-link' to="/profile">Profil</NavLink>
                            </>
                        ) : null}
                    </Nav>
                    <Nav className="ml-auto normal-auth-section">
                        {loggedIn ? (
                            <>
                                <span className="normal-logged-in-as">Inloggad som:</span>
                                <NavLink className="normal-profile-link" to="/profile">
                                    {user.avatar_url ? (
                                        <img src={user.avatar_url} alt="Avatar" className="normal-avatar" />
                                    ) : (
                                        <span className="normal-user-name">{user.full_name}</span>
                                    )}
                                </NavLink>
                                <Button className='normal-btn-logout' variant="danger" onClick={logout}>Logga ut</Button>
                            </>
                        ) : (
                            <Button className='normal-btn-primary' variant='primary' onClick={handleShowLogin}>Logga in</Button>
                        )}
                    </Nav>
                </Container>
            </Navbar>
            <LoginModal show={showLoginModal} handleClose={handleCloseLogin} />
        </>
    );
};

export default NormalNavbar;
