export const welcomeText = {
	"websiteName": "Drink Drink",
	"welcome": "Welcome to Drink Drink!",
	"introduction": "At Drink Drink, we are a group of friends with a shared passion for discovering, creating, and tasting the most exquisite and unique beverages from around the world. Our mission is to bring you along on our journey as we explore the diverse world of mixology and share our experiences with you.",
	"storyTitle": "Our Story",
	"story": "It all started when we realized that there were countless drink recipes waiting to be discovered and enjoyed. We knew that each new creation brought with it an opportunity to connect with friends, engage in lively conversations, and make lasting memories. This is why we decided to embark on this exciting adventure and created Drink Drink as a platform to document our experiences and share them with fellow enthusiasts like you.",
	"whatWeDoTitle": "What We Do",
	"whatWeDo": "Each week, we gather together to try out new drink recipes we've discovered or crafted ourselves. From classic cocktails to innovative concoctions, our taste buds are always eager to explore new flavors and styles. After a fun-filled tasting session, we rate each drink based on taste, presentation, and originality.",
	"joinCommunityTitle": "Join Our Community",
	"joinCommunity": "As part of the Drink Drink community, you'll have access to our ever-growing collection of drink recipes, along with our personal ratings and recommendations. We'll also share stories, tips, and tricks from our tasting sessions, as well as insights from fellow mixology enthusiasts.",
	"closing": "Whether you're a seasoned mixologist or just starting your journey into the world of delightful beverages, Drink Drink is here to inspire, educate, and entertain. So, grab your favorite glass, and let's toast to new friendships and unforgettable experiences!",
	"subscribe": "Subscribe to our newsletter to stay up-to-date with our latest discoveries, and follow us on social media for behind-the-scenes glimpses of our tasting adventures.",
	"closingSalutation": "Cheers!",
	"author": "Sven",
	"team": "Drink Drink Team"
  
}
