import React from 'react';
import { Outlet } from 'react-router-dom';
import { useDrink } from '../../context/drink-context';
import DrinkList from '../../components/lists/drink-list';

const DrinkPage: React.FC = () => {
    const { drinks } = useDrink()


    return (
        <div style={{maxWidth: "1200px", margin: "0 auto"}}>
            <DrinkList drinks={drinks} />
        </div>
    );
};

export default DrinkPage;
