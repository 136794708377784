import { FunctionComponent, createContext, useContext, useEffect, useState } from "react";
import { AuthContextType, ProviderProps } from "../types/context-types";
import { Session } from "@supabase/supabase-js";
import { supabase } from "../supabase/supabase";
import { APISTATUS, ApiResult } from "../types/api";
import { User } from "../models/user";

// Create a context with an initial undefined value but tell TypeScript the shape of the context
const AuthContext = createContext<AuthContextType | null>(null);

// Custom hook to use the auth context
export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === null) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

const emptyUser: User = {
    id: "",
    full_name: "",
    avatar_url: "",
    updated_at: "",
}

export const AuthProvider: FunctionComponent<ProviderProps> = ({ children }) => {
    const [session, setSession] = useState<Session | null>(null);
    const [user, setUser] = useState<User>(emptyUser)
    const [resetPasswordSession, setResetPasswordSession] = useState<Session | null>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        const initializeSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            setSession(session)
            setLoading(false);
        };

        initializeSession();
        const { data: subscription } = supabase.auth.onAuthStateChange((event, session) => {
            if (event === 'SIGNED_IN') {


            } else if (event === 'SIGNED_OUT') {
                setSession(null);
                setUser(emptyUser)
                setResetPasswordSession(null)
            } else if (event === 'PASSWORD_RECOVERY') {
           

            } else if (event === 'TOKEN_REFRESHED') {
           

            }
        });

        return () => {
            subscription.subscription.unsubscribe()
        }
    }, [])


    useEffect(() => {
        const assignUser = async (session: Session) => {
            const { data, error } = await supabase.from('profiles').select('*').eq('id', session.user.id).single()

            if (error) return

            setUser(data)

        }
        if (session) {
            assignUser(session)
        }


    }, [session])

    async function signInWithEmail(email: string, password: string): Promise<ApiResult<Session>> {
        const { data, error } = await supabase.auth.signInWithPassword({
            email: email,
            password: password,
        });

        if (error) return { status: APISTATUS.FAILURE, message: error.message }
        setSession(data.session)
        return { status: APISTATUS.SUCCESS, data: data.session, message: "OK" }
    }

    const logout = async () => {
        try {
            const { error } = await supabase.auth.signOut();

        } catch (error) {
            console.error(error);
        }
    };


    const updatePassword = async (newPassword: string): Promise<ApiResult<null>> => {
        try {
            const { data, error } = await supabase.auth.updateUser({
                password: newPassword
            })

            if (error) return { status: APISTATUS.FAILURE, message: error.message }

            return { status: APISTATUS.SUCCESS, data: null, message: "Lösenord uppdaterat!" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Kunde inte uppdatera lösenordet: " + error }
        }

    }


    const sendResetPasswordLink = async (email: string): Promise<ApiResult<null>> => {
        try {
            const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
                redirectTo: 'drinkdrinkapp://resetpassword',

            })

            if (error) return { status: APISTATUS.FAILURE, message: "Kunde inte skicka länk. Kontrollera din epost." }

            return { status: APISTATUS.SUCCESS, data: null, message: "Länk skickad till din epost! Glöm inte att kolla din skräppost." }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Kunde inte skicka länk: " + error }
        }
    }

    const updateUserObject = (user: User) => {
        setUser(user)
    }

    const value = {
        session,
        user,
        resetPasswordSession,
        loading,
        updateUserObject,
        logout,
        sendResetPasswordLink,
        updatePassword,
        signInWithEmail
    };

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>;
};