import { useEffect } from "react";
import { Container, Spinner, Row, Col, Button } from "react-bootstrap";
import { useCurrentEvent } from "../../context/current-event-context";
import { APISTATUS } from "../../types/api";
import { useNavigate, useParams } from "react-router-dom";
import UnPublishedDrinksList from "../../components/events/unpublished-drink-list";
import "./css/publish-drink-page.css";

export const UNPUBLISHED_DRINK_SCREEN_TITLE = "Inga drinkar att publicera!"
export const UNPUBLISHED_DRINK_SCREEN_INFO = "Gå till din profil för att redigera dina drinkar."

export const PublishDrinkPage = () => {
    const {eventID} = useParams()
    const navigate = useNavigate();
    const { unPublishedDrinks, selectedDrink, loadingContent, publishDrink, selectDrinkForPublish, refreshUsersUnpublishedDrinks } = useCurrentEvent();


    useEffect(() => {
        refreshUsersUnpublishedDrinks();
    }, []);

    const handlePublishDrink = async () => {
        const res = await publishDrink();

        if (res.status === APISTATUS.SUCCESS) {
            navigate('/event/' + eventID, { replace: true });
        }
    };

    return (
        <Container className="publish-drink-page">
            {loadingContent && <Spinner animation="border" />}
            <Row>
                <Col>
                    {unPublishedDrinks && unPublishedDrinks.length !== 0 ? (
                        <UnPublishedDrinksList
                            drinks={unPublishedDrinks}
                            selectedDrink={selectedDrink}
                            setSelectedDrink={selectDrinkForPublish}
                        />
                    ) : (
                        <div className="info-container">
                            <h1 className="info-title">{UNPUBLISHED_DRINK_SCREEN_TITLE}</h1>
                            <p className="info-text">{UNPUBLISHED_DRINK_SCREEN_INFO}</p>
                        </div>
                    )}
                </Col>
            </Row>
            <div className="button-fixed-container">
                <Button
                    variant="primary"
                    onClick={handlePublishDrink}
                    disabled={unPublishedDrinks.length === 0 || selectedDrink === undefined}
                >
                    Publicera drink
                </Button>
            </div>
        </Container>
    );
};

export default PublishDrinkPage;
