import { Pagination } from "@supabase/supabase-js";
import { FunctionComponent, ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { BaseSpirit, Drink } from "../models/drink";
import { DrinkContextType, ProviderProps } from "../types/context-types";
import { supabase } from "../supabase/supabase";
import { APISTATUS, ApiResult } from "../types/api";



const DrinkContext = createContext<DrinkContextType | null>(null);

export const DrinkProvider: FunctionComponent<ProviderProps> = ({ children }) => {
    const [drinks, setDrinks] = useState<Drink[]>([])

    useEffect(() => {
        const fetchDrinks = async () => {
            const { data, error } = await supabase
                .from('drinks_view')
                .select('*')
                .is('public', true)
                .returns<Drink[]>()

            if (error) {
                console.log(error)
            } else {
                setDrinks(data)
            }
        }
        fetchDrinks()


    }, [])


    const getDrinkById = async (id: number): Promise<ApiResult<Drink>> => {
        const { data, error } = await supabase
            .from('drinks_view')
            .select('*')
            .eq('drink_id', id)
            .returns<Drink>()
            .single()
        if (error) return {status: APISTATUS.FAILURE, message: error.message}

        return {status: APISTATUS.SUCCESS, data, message: "Drink hämtad"}
    }


    const value = {
        drinks,
        getDrinkById
    }

    return (<DrinkContext.Provider value={value}>{children}</DrinkContext.Provider>)
}


export const useDrink = (): DrinkContextType => {
    const context = useContext(DrinkContext);
    if (context === null) {
        throw new Error('useDrink must be used within an DrinkProvider');
    }
    return context;
};