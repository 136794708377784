import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/auth-context";
import { Spinner } from "react-bootstrap";


export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { session, loading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && session === null) {
            navigate('/', { replace: true });
        }
    }, [navigate, session, loading]);

    if (loading) {
        return <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner> // Or any loading spinner/UI
    }

    return <>{children}</>;
};
