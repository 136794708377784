
import { jwtDecode } from "jwt-decode";
import { decode } from "base-64";
import { Session } from "@supabase/supabase-js";
import { supabase } from "../supabase/supabase";
import { ApiResult, APISTATUS } from "../types/api";
import { imageService } from "./ImageService";
import { User, UserUpdate } from "../models/user";
global.atob = decode;


const USER_CREATED_SUCCESS_MESSAGE = undefined
const USER_UPDATED_SUCCESS_MESSAGE = "Profil uppdaterad"
const USER_DELETED_SUCCESS_MESSAGE = undefined
const USER_GET_SUCCESS_MESSAGE = "Profil hämtad"


const USER_CREATED_FAIL_MESSAGE = undefined
const USER_UPDATED_FAIL_MESSAGE = "Kunde inte uppdatera profil"
const USER_DELETED_FAIL_MESSAGE = undefined
const USER_GET_FAIL_MESSAGE = "Kunde inte hämta profil"


const USER_CREATED_PARTIAL_MESSAGE = undefined
const USER_UPDATED_PARTIAL_MESSAGE = undefined
const EUSER_DELETED_PARTIAL_MESSAGE = undefined

const generateNewUrlEveryMonthInSeconds = 60 * 60 * 24 * 30
const generateNewUrlEveryMonthInHours = 24 * 30 // 1 Month
const oneDayLeftInHours = 24

class UserService{

    async createUser(email: string, password: string, fullName: string): Promise<ApiResult<null>> {
        try {
            const { data: { session }, error } = await supabase.auth.signUp({
                email: email,
                password: password,
                options: {
                    emailRedirectTo: "drinkdrinkapp://login",
                    data: {
                        full_name: fullName
                    }
                }
            });


            if (error) return { status: APISTATUS.FAILURE, message: error.message }

            return { status: APISTATUS.SUCCESS, data: null, message: "Kolla din email för verifiering av kontot!" }


        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Error while registration" }
        }
    }


    async getUser(userID: string | Session): Promise<ApiResult<User>> {
        try {
            const id = typeof userID === 'string' ? userID : userID.user.id
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
                .eq('id', id)
                .single()
            if (error) return { status: APISTATUS.FAILURE, message: USER_GET_FAIL_MESSAGE }

            if (data.avatar_url) {
                const shouldUpdate = this.shouldUpdateSignedUrl(data.avatar_url, oneDayLeftInHours)

                if (shouldUpdate) {
                    const result = await imageService.createSignedProfileImage(data.id, generateNewUrlEveryMonthInSeconds)
                    if (result.status === APISTATUS.SUCCESS) {
                        const time = 60 * 60 * 24 * 30 // 1 month
                        await this.updateAvatarUrl(id, time)
                    }

                }
            }

            return { status: APISTATUS.SUCCESS, data, message: USER_GET_SUCCESS_MESSAGE }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: USER_GET_FAIL_MESSAGE + userID.toString() }
        }
    }


    async getAllUsers(): Promise<ApiResult<User[]>> {
        try {
            const { data, error } = await supabase.from('profiles').select()

            if (error) return { status: APISTATUS.FAILURE, message: USER_GET_FAIL_MESSAGE }

            const mapped = data.map(user => ({ ...user, avatar_url: user.avatar_url ?? undefined }))

            return { status: APISTATUS.SUCCESS, data: mapped, message: USER_GET_SUCCESS_MESSAGE }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to get all users" }
        }
    }

    async updateUser(userID: string, user: UserUpdate, file?: File, deleteProfilePicture?: boolean,): Promise<ApiResult<User>> {
        try {
            let imageUrl = user.avatar_url ?? null

            if (file) {
                const time = 60 * 60 * 24 * 30 // 1 Month
                const upload = await imageService.uploadProfileImage(userID, file, time, true)

                if (upload.status === APISTATUS.SUCCESS) {
                    imageUrl = upload.data
                }
            } else if (deleteProfilePicture) {
                const deletePic = await imageService.deleteProfileImage(userID)

                if (deletePic.status === APISTATUS.SUCCESS) {
                    imageUrl = null
                }
            }

            const { data, error } = await supabase.from('profiles')
                .update({
                    full_name: user.full_name,
                    avatar_url: imageUrl
                })
                .eq("id", userID)
                .select()
                .single()

            if (error) return { status: APISTATUS.FAILURE, message: USER_UPDATED_FAIL_MESSAGE }

            const domain: User = { ...data, avatar_url: data.avatar_url ?? undefined }


            return { status: APISTATUS.SUCCESS, data: domain, message: USER_UPDATED_SUCCESS_MESSAGE }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: USER_UPDATED_FAIL_MESSAGE }
        }
    }


    async updateAvatarUrl(userID: string, expiresInSeconds: number): Promise<ApiResult<null>> {
        try {
            const result = await imageService.createSignedProfileImage(userID, expiresInSeconds)
            if (result.status === APISTATUS.FAILURE) return { status: APISTATUS.FAILURE, message: result.message }
            const { error } = await supabase.from('profiles').update({ avatar_url: result.data }).eq('id', userID)
            if (error) return { status: APISTATUS.FAILURE, message: error.message }
            return { status: APISTATUS.SUCCESS, data: null, message: "Url til profilbilden har uppdaterats" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Could not update profile url" }

        }
    }

    async assignImageUrlToProfile(user: User, expiresInSeconds: number): Promise<ApiResult<string>> {
        try {
            const result = await imageService.createSignedProfileImage(user.id, expiresInSeconds)

            if (result.status === APISTATUS.FAILURE) return result

            const now = new Date().toISOString();
            const { data, error } = await supabase
                .from('profiles')
                .update({ ...user, avatar_url: result.data, updated_at: now })
                .eq('id', user.id);


            if (error) return { status: APISTATUS.FAILURE, message: "Kunde inte uppdatera profilbild" }

            return { status: APISTATUS.SUCCESS, data: result.data, message: "Profilbild uppdaterad!" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Kunde inte uppdatera profilbild" }
        }
    }



    private getTimeLeftFromSignedUrlInHours(url: string): number {
        const token = url.split("token=")[1];
        const decodedPayload = jwtDecode(token);

        if (decodedPayload.exp) {
            const expirationDate = new Date(decodedPayload.exp * 1000);
            if (isNaN(expirationDate.getTime())) {
                throw new Error('Invalid expiration date')
            }
            const now = new Date();
            const timeLeftMs = expirationDate.getTime() - now.getTime();
            return timeLeftMs / (1000 * 60 * 60);
        } else {
            throw new Error('Invalid jwt')
        }
    }


    shouldUpdateSignedUrl(url: string, thresholdInHours: number): boolean {
        const timeLeft = this.getTimeLeftFromSignedUrlInHours(url)
        console.log("thresholdInHours", thresholdInHours, "timeleft", timeLeft);

        return thresholdInHours >= timeLeft
    }



}

const createUserService = () => (new UserService)

export const userService = createUserService() 