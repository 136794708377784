import { Params } from "react-router-dom";
import { Drink } from "../models/drink";
import { supabase } from "../supabase/supabase";
import { eventService } from "../services/EventService";
import { APISTATUS } from "../types/api";

type LoaderParams = {
    params: Params<string>
}


export const eventLoader = async ({ params }: LoaderParams) => {
    const eventID = Number(params.eventID);
    const res = await eventService.getEvent(eventID);

    if (res.status === APISTATUS.FAILURE) {
       

        // Fallback for other errors
        throw new Response("Not Found", { status: 404, statusText: res.message });
    }

    return res.data;
};
