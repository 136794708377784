import { Pagination } from "react-bootstrap"
import { supabase } from "../supabase/supabase"
import { ApiResult, APISTATUS, StorageBucket, SignedImageUrl } from "../types/api"




const EVENT_PICTURE_BUCKET = 'event-pictures'
const DRINK_PICTURES_BUCKET = 'drink-pictures'
const AVATARS_BUCKET = 'avatars'
const CACHECONTROL = '86400'

type SignedUrl = { path: string, signedUrl: string, error: string }

export class ImageService {


    // ---------- CORE FUNCTIONS ----------

    async getImageFromStorage(bucket: string, filePath: string, options?: { signed: boolean, expiresIn: number }): Promise<string> {
        let result: string | '' | undefined

        try {
            if (options?.signed) {
                const { data, error } = await supabase.storage.from(bucket).createSignedUrl(filePath, options.expiresIn)

                if (error) throw error

                return data.signedUrl
            } else {
                const { data } = supabase.storage.from(bucket).getPublicUrl(filePath)
                result = data.publicUrl
            }

            if (!result) throw new Error(`Failed to fetch image from bucket: ${bucket} and filepath: ${filePath}`)

            return result

        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async deleteImageFromStorage(bucket: string, filepath: string): Promise<void> {
        try {
            const { data, error } = await supabase.storage.from(bucket).remove([filepath])

            if (error) throw error

        } catch (error) {
            console.error("deleteImage", error);
            throw error
        }

    }

    async uploadImageToStorage(bucket: string, filepath: string, file: File, overrideFile: boolean): Promise<string> {
        try {
            const { data, error } = await supabase.storage.from(bucket)
                .upload(filepath, file, {
                    contentType: file.type,
                    upsert: overrideFile,
                    cacheControl: CACHECONTROL
                });
            if (error) throw error;
            return data.path;
        } catch (error) {
            console.error("UploadImage", error);
            throw error;
        }
    }

    async findImageFile(bucket: string, filepath?: string, searchString?: string, limit: number = 10): Promise<ApiResult<string>> {

        try {
            const { data, error } = await supabase.storage.from(bucket).list(filepath, { search: searchString, limit: limit })


            if (error) return { status: APISTATUS.FAILURE, message: error.message }

            if (!data) return { status: APISTATUS.FAILURE, message: "Could not find any file with that name" }

            return { status: APISTATUS.SUCCESS, data: data[0].name, message: "OK" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Error occured when finding image-file" }
        }

    }


    private createPublicUrl(filepath: string): string {
        return `https://${process.env.REACT_APP_SUPABASE_PROJECT_ID}.supabase.co/storage/v1/object/public/${filepath}`
    }

    generatePublicUrl(bucket: StorageBucket, filepath: string): string {
        return `https://${process.env.REACT_APP_SUPABASE_PROJECT_ID}.supabase.co/storage/v1/object/public/${bucket}/${filepath}`
    }



    // ---------- EVENT IMAGE OPERATIONS ----------

    async getEventImage(userID: string, eventID: number, options?: { signed: boolean, expiresIn: number }): Promise<ApiResult<string>> {
        try {
            const result = await this.getImageFromStorage(EVENT_PICTURE_BUCKET, `${userID}/${eventID.toString()}`, options)
            return { status: APISTATUS.SUCCESS, data: result, message: "OK" }
        } catch (error) {
            return { status: APISTATUS.FAILURE, message: "Failed to get event image" }
        }

    }

    async uploadEventImage(userID: string, eventID: number, file: File): Promise<ApiResult<string>> {
        try {
            const result = await this.uploadImageToStorage(EVENT_PICTURE_BUCKET, `${userID}/${eventID}`, file, true);
            const url = this.generatePublicUrl(EVENT_PICTURE_BUCKET, result);
            return { status: APISTATUS.SUCCESS, data: url, message: "OK" };
        } catch (error) {
            return { status: APISTATUS.FAILURE, message: "Failed to upload event image" };
        }
    }



    async deleteEventImage(userID: string, eventID: number): Promise<ApiResult<null>> {
        try {
            await this.deleteImageFromStorage(EVENT_PICTURE_BUCKET, `${userID}/${eventID}`)
            return { status: APISTATUS.SUCCESS, data: null, message: "OK" }
        } catch (error) {
            return { status: APISTATUS.FAILURE, message: "Failed to delete event image" }
        }

    }


    // ---------- DRINK IMAGE OPERATIONS ----------



    async uploadDrinkImage(userID: string, drinkID: number, file: File): Promise<ApiResult<string>> {
        try {
            const timestamp = Date.now();
            const result = await this.uploadImageToStorage(DRINK_PICTURES_BUCKET, `${userID}/${drinkID}_${timestamp}`, file, true);
            const url = this.generatePublicUrl(DRINK_PICTURES_BUCKET, result);
            return { status: APISTATUS.SUCCESS, data: url, message: "OK" };
        } catch (error) {
            return { status: APISTATUS.FAILURE, message: "Failed to upload drink image" };
        }
    }


    async deleteDrinkImage(userID: string, drinkID: number): Promise<ApiResult<null>> {
        try {
            const res = await this.findImageFile(DRINK_PICTURES_BUCKET, userID, drinkID.toString())

            if (res.status === APISTATUS.FAILURE) throw res.message

            await this.deleteImageFromStorage(DRINK_PICTURES_BUCKET, `${userID}/${res.data}`)

            return { status: APISTATUS.SUCCESS, data: null, message: "OK" }
        } catch (error) {
            return { status: APISTATUS.FAILURE, message: "Failed to delete drink image" }
        }
    }

    async updateDrinkImage(userID: string, drinkID: number, file: File): Promise<ApiResult<string>> {
        try {
            const { status: deleteStatus, message: errorMessage } = await this.deleteDrinkImage(userID, drinkID);
            if (deleteStatus === APISTATUS.FAILURE) return { status: APISTATUS.FAILURE, message: errorMessage };
            const upload = await this.uploadDrinkImage(userID, drinkID, file);
            if (upload.status === APISTATUS.FAILURE) return { status: APISTATUS.FAILURE, message: upload.message };
            return { status: APISTATUS.SUCCESS, data: upload.data, message: "Bild uppdaterad" };
        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to update drink image" };
        }
    }



    // ---------- PROFILE IMAGE OPERATIONS ----------

    async createSignedProfileImage(userID: string, expiresInSeconds: number): Promise<ApiResult<string>> {
        try {
            const image_url = `${userID}/avatar1`

            const { data, error } = await supabase.storage.from(AVATARS_BUCKET).createSignedUrl(image_url, expiresInSeconds)


            if (error) return { status: APISTATUS.FAILURE, message: error.message }

            return { status: APISTATUS.SUCCESS, data: data.signedUrl, message: "OK" }

        } catch (error) {
            return { status: APISTATUS.FAILURE, message: "Failed to get signed profile image" }
        }
    }

    async uploadProfileImage(userID: string, file: File, expiresIn: number, overrideFile: boolean): Promise<ApiResult<string>> {
        try {
            await this.uploadImageToStorage(AVATARS_BUCKET, `${userID}/avatar1`, file, overrideFile);
            const url = await this.createSignedProfileImage(userID, expiresIn);
            if (url.status === APISTATUS.FAILURE) {
                return { status: APISTATUS.FAILURE, message: "Failed to get signed url after upload" };
            }
            return { status: APISTATUS.SUCCESS, data: url.data, message: "OK" };
        } catch (error) {
            return { status: APISTATUS.FAILURE, message: "Failed to upload profile image" };
        }
    }

    async deleteProfileImage(userID: string): Promise<ApiResult<null>> {
        try {
            await this.deleteImageFromStorage(AVATARS_BUCKET, `${userID}/avatar1`)
            return { status: APISTATUS.SUCCESS, data: null, message: "OK" }
        } catch (error) {
            return { status: APISTATUS.FAILURE, message: "Failed to delete profile image" }
        }

    }

    extractIDFromFilepath = (filePath: string) => (filePath.split('/')[filePath.split('/').length - 1])
}


function createImageService() {
    return new ImageService()
}

export const imageService = createImageService()