import { FunctionComponent, createContext, useContext, useEffect, useState } from "react";
import { DrinkEvent, EventForm } from "../models/event";
import { EventContextType, ProviderProps } from "../types/context-types";
import { eventService } from "../services/EventService";
import { APISTATUS, ApiResult } from "../types/api";
import { useParams } from "react-router-dom";

const EventContext = createContext<EventContextType | null>(null);

export const EventProvider: FunctionComponent<ProviderProps> = ({ children }) => {
    const { eventID } = useParams()
    const [allEvents, setAllEvents] = useState<DrinkEvent[]>([])
    const [selectedDrinkEvent, setSelectedDrinkEvent] = useState<DrinkEvent>()

    useEffect(() => {
        const getAllEvents = async () => {
            const res = await eventService.getAllEvents()
            if (res.status === APISTATUS.SUCCESS) {
                setAllEvents(res.data)
            }

        }
        getAllEvents()

    }, [])

   
    useEffect(() => {
        const updateCurrentEvent = async (eventID: number) => {
            const result = await eventService.getEvent(eventID)
            if (result.status === APISTATUS.SUCCESS && result.data) {
                onSetSelectedDrinkEvent(result.data)
            }

            if(result.status === APISTATUS.FAILURE) {
                throw new Response("Not Found", { status: 404, statusText: result.message });
            }

        }
        if (!selectedDrinkEvent && !Number.isNaN(Number(eventID))) {
            updateCurrentEvent(Number(eventID))
        }
        

    }, [eventID])




    const createNewEvent = async (eventForm: EventForm, file?: File) => {
        const res = await eventService.createNewEvent(eventForm, file)

        return res
    }

    const deleteEvent = async (userID: string, eventID: number): Promise<ApiResult<null>> => {
        const res = await eventService.deleteEvent(userID, eventID)
        if (res.status === APISTATUS.SUCCESS) {
            setAllEvents(prev => prev.filter(event => event.event_id !== eventID))
        }
        return res
    }

    const getEvent = async (eventID: number) => {
        return await eventService.getEvent(eventID)
    }

    const joinEvent = async (userID: string, eventID: number) => {
        return await eventService.joinEvent(userID, eventID)
    }

    const updateEvent = async (eventID: number, event: EventForm, file?: File | undefined) => {
        const res = await eventService.updateEvent(eventID, event, file)

        return res
    }

    const onSetSelectedDrinkEvent = (event: DrinkEvent) => {
        setSelectedDrinkEvent(event)
    }




    const value: EventContextType = {
        allEvents,
        selectedDrinkEvent,
        onSetSelectedDrinkEvent,
        createNewEvent,
        deleteEvent,
        getEvent,
        joinEvent,
        updateEvent,

    }

    return (
        <EventContext.Provider value={value}>{children}</EventContext.Provider>
    )
}


export const useEvent = (): EventContextType => {
    const context = useContext(EventContext);
    if (context === null) {
        throw new Error('useEvent must be used within an EventProvider');
    }
    return context;
};