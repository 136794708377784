import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { IoIosCloseCircle, IoIosCheckmarkCircle } from "react-icons/io";
import { Voter } from '../../models/participant';
import './css/voting-list.css'; // Import the CSS file for styling

type VotingListProps = {
    voters: Voter[];
    onlyFirstName?: boolean;
};

const VotingList: React.FC<VotingListProps> = ({ voters, onlyFirstName = true }) => {
    return (
        <div className="voting-list-container">
            <h2 className="voting-list-title">Röster från:</h2>
            <ListGroup>
                {voters.map(item => {
                    const name = onlyFirstName ? item.full_name.split(' ')[0] : item.full_name;
                    return (
                        <ListGroup.Item key={item.user_id} className="voting-list-item">
                            <span className="voting-list-dot">•</span>
                            <span className="voting-list-name">{name}</span>
                            {item.voted ? (
                                <IoIosCheckmarkCircle size={24} color="seagreen" />
                            ) : (
                                <IoIosCloseCircle size={24} color="firebrick" />
                            )}
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </div>
    );
};

export default VotingList;
