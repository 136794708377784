import React from 'react';
import { Card } from 'react-bootstrap';

import './css/current-drink-item.css';
import { Drink } from '../../models/drink';

type Props = {
    drink: Drink;
    onClick: (drink: Drink) => void;
};

const CurrentDrinkItem: React.FC<Props> = ({ drink, onClick }) => (
    <Card className="current-drink-item" onClick={() => onClick(drink)}>
        <Card.Img variant="top" src={drink.drink_image_url || '/placeholder.png'} className="current-drink-img" />
        <Card.Body>
            <Card.Title>{drink.drink_name}</Card.Title>
            <Card.Text>Skapare: {drink.creator_full_name}</Card.Text>
        </Card.Body>
    </Card>
);

export default CurrentDrinkItem;
