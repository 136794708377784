import { Params, useLocation } from "react-router-dom";
import { Drink } from "../models/drink";
import { supabase } from "../supabase/supabase";

type LoaderParams = {
    params: Params<string>
}


export const drinkLoader = async ({ params }: LoaderParams) => {
  


    const { data, error } = await supabase
        .from('drinks_view')
        .select('*')
        .eq('drink_id', params.drinkID)
        .returns<Drink>()
        .single();

    if (error) {
        // Handle specific Supabase error codes
        if (error.code === 'PGRST116') {
            // No rows or multiple rows returned
            throw new Response("Not Found", { status: 404, statusText: "Drink not found" });
        }

        // Handle other potential Supabase error codes
        if (error.code === 'PGRST121') {
            throw new Response("Bad Request", { status: 400, statusText: "Invalid request" });
        }

        // Fallback for other errors
        throw new Response("Server Error", { status: 500, statusText: "An unexpected error occurred" });
    }

    return data;
};
