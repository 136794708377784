import { Medal } from "./medal";

export type Drink = {
    created_at: string;
    creator_avatar_url?: string | undefined;
    creator_full_name: string;
    creator_id: string;
    drink_id: number;
    drink_image_url?: string | undefined;
    drink_name: string;
    event_id?: number | undefined;
    ingredients: DrinkIngredient[];
    instructions?: string | undefined;
    public: boolean;
    published_at?: string | undefined;
}

export type DrinkWithMedal = Drink & { medal?: Medal }

export type DrinkIngredient = {
    name: string
    amount: number,
    unit: DrinkUnit
}

export type BaseSpirit =
    'Absint' | 'Bitter' | 'Brandy' | 'Calvados' | 'Cognac' | 'Gin' | 'Grappa' | 'Likör' | 'Mezcal' | 'Punsch' | 'Rom' | 'Starkvin' | 'Tequila' | 'Vodka' | 'Whisky'

export enum DrinkUnit {
    CL = "cl",
    ST = "st"
}

export type DrinkUpdate = {
    created_at?: string
    creator_id?: string
    drink_id?: number
    drink_name?: string
    event_id?: number | null | undefined;
    image_url?: string | null | undefined;
    public?: boolean | undefined;
    published_at?: string | null | undefined;
    ingredients?: DrinkIngredient[] | undefined;
    instructions?: string | undefined;
}

export type DrinkEntity = {
    ingredients_old?: string[] | undefined;
    created_at: string;
    creator_id: string;
    drink_id: number;
    drink_name: string;
    public: boolean;
    event_id?: number | undefined;
    ingredients: DrinkIngredient[];
    instructions?: string | undefined;
    image_url?: string | undefined;
    published_at?: string | undefined;
}

export type DrinkInsert = {
    public?: boolean | undefined;
    drink_name: string;
    image_url?: string | undefined;
    ingredients?: DrinkIngredient[] | undefined;
    instructions?: string | undefined;
}