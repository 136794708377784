import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import styles from "../../css/drink-details-page.module.css";
import { Drink } from "../../models/drink";
import { APISTATUS } from "../../types/api";
import { Medal } from "../../models/medal";
import { drinkscoreService } from "../../services/DrinkscoreService";
import ScoreDisplay from "../../components/drinks/score-display";

export const DrinkDetailPage: React.FC = () => {
    const data = useLoaderData() as Drink
    const [currentDrink, setCurrentDrink] = useState<Drink>(data);
    const localDate = new Date(currentDrink.created_at);
    const [totalScoreForDrink, setTotalScoreForDrink] = useState<number>()
    const [eventScore, setEventScore] = useState<{ score: number, event: string }>()
    const [drinkRank, setDrinkRank] = useState<Medal>()

    useEffect(() => {
        const fetchData = async (drink: Drink) => {
            const getEventScore = await drinkscoreService.getTotalScoreFromEvent(drink.drink_id)
            if (getEventScore.status === APISTATUS.SUCCESS) {
                setEventScore(getEventScore.data)
            }
            const getTotalScore = await drinkscoreService.getAvarageScoreForDrink(drink.drink_id)

            if (getTotalScore.status === APISTATUS.SUCCESS) {
                setTotalScoreForDrink(getTotalScore.data ?? undefined)
            }
        }

        if (data) {
            fetchData(data)
        }
    }, [currentDrink, data])

    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <h1 className={styles.title}>{currentDrink.drink_name}</h1>
                <ScoreDisplay totalScoreForDrink={totalScoreForDrink} eventScore={eventScore} />
                <div className={styles.ingredients_container}>
                    {currentDrink.drink_image_url && (
                        <img
                            className={styles.drinkImage}
                            src={currentDrink.drink_image_url}
                            alt={currentDrink.drink_name}
                        />
                    )}
                    <div className={styles.ingredients}>
                        <h2>Ingredienser</h2>
                        {currentDrink.ingredients.map((ingredient) => (
                            <p key={ingredient.name}>
                                {ingredient.name} {ingredient.amount} {ingredient.unit}
                            </p>
                        ))}
                    </div>
                </div>
                <h2>Instruktioner</h2>
                <p className={styles.description}>{currentDrink.instructions}</p>
                <p><b>Skapad av: {currentDrink.creator_full_name} - {localDate.toLocaleDateString()}</b></p>
            </div>
        </div>
    );
};

export default DrinkDetailPage;
