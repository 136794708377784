import { DrinkscoreComment } from "../models/comment"
import { EventDrinkscoreRow, DrinkscoreRow, ScoreboardItem } from "../models/drink-score"
import { MedalCollection } from "../models/medal"
import { supabase } from "../supabase/supabase"
import { ApiResult, APISTATUS } from "../types/api"


const DRINKSCORE_CREATED_SUCCESS_MESSAGE = "Röstning lagd!"
const DRINKSCORE_UPDATED_SUCCESS_MESSAGE = "Röstning uppdaterad!"
const DRINKSCORE_DELETED_SUCCESS_MESSAGE = "Röstning borttagen"
const DRINKSCORE_GET_SUCCESS_MESSAGE = "Röstningar hämtade"


const DRINKSCORE_CREATED_FAIL_MESSAGE = "Röstning misslyckades"
const DRINKSCORE_UPDATED_FAIL_MESSAGE = "Röstning kunde ej uppdateras"
const DRINKSCORE_DELETED_FAIL_MESSAGE = "Röstning kunde inte bli borttagen"
const DRINKSCORE_GET_FAIL_MESSAGE = "Röstningar kunde ej hämtas"


class DrinkscoreService {

    static readonly DRINKSCORES_TABLE = "drinkscores"
    static readonly DRINKSCORES_VIEW = "drinkscores_view"
    static readonly EVENT_DRINKSCORES_TABLE = "event_drinkscores"
    static readonly EVENT_DRINKSCORES_VIEW = "event_drinkscores_view"
    static readonly AVG_DRINKSCORES_VIEW = "avg_drinkscores_view"


    async getAvarageScoreForDrink(drinkID: number): Promise<ApiResult<number | null>> {
        try {
            const { data, error } = await supabase.from(DrinkscoreService.AVG_DRINKSCORES_VIEW).select(`avg_score`).eq('drink_id', drinkID).single()

            if (error) return { status: APISTATUS.FAILURE, message: error.message }

            return { status: APISTATUS.SUCCESS, data: data.avg_score ?? null, message: "OK" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Could not get avarage score" }

        }
    }


    async getEventDrinkscoresForDrink(eventID: number, drinkID: number): Promise<ApiResult<EventDrinkscoreRow[]>> {
        try {
            const { data, error } = await supabase.from(DrinkscoreService.EVENT_DRINKSCORES_TABLE).select().eq('event_id', eventID).eq('drink_id', drinkID)

            if (error) return { status: APISTATUS.FAILURE, message: error.message }

            return { status: APISTATUS.SUCCESS, data, message: 'OK' }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to get event drink scores" }
        }
    }


    async getScoreAsComment(userID: string, drinkID: number): Promise<ApiResult<DrinkscoreComment>> {
        try {
            const { data, error } = await supabase.from(DrinkscoreService.DRINKSCORES_VIEW).select().eq('drink_id', drinkID).eq('voter_id', userID).single()

            if (error) return { status: APISTATUS.FAILURE, message: error.message }

            const transformedData: DrinkscoreComment = {
                comment: data.drinkscore_comment,
                comment_id: userID + '_' + drinkID.toString(),
                creator_full_name: data.voter_full_name,
                creator_id: data.voter_id,
                drink_id: data.drink_id,
                creator_avatar_url: data.voter_avatar_url,
                score: data.score,
                created_at: data.created_at
            }

            return { status: APISTATUS.SUCCESS, data: transformedData, message: "OK" }


        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to get scores as comments" }

        }
    }

    async getScoresAsComments(drinkID: number): Promise<ApiResult<DrinkscoreComment[]>> {
        try {

            const { data, error } = await supabase
                .from(DrinkscoreService.DRINKSCORES_VIEW)
                .select()
                .eq('drink_id', drinkID)

            if (error) return { status: APISTATUS.FAILURE, message: "Kunde inte hämta poäng kommentarer" }

            const transformedData: DrinkscoreComment[] = data.length !== 0 ? data.map(score => ({
                comment: score.drinkscore_comment,
                comment_id: score.voter_id + '_' + drinkID.toString(),
                creator_full_name: score.voter_full_name,
                creator_id: score.voter_id,
                drink_id: score.drink_id,
                creator_avatar_url: score.voter_avatar_url,
                score: score.score,
                created_at: score.created_at
            })) : []


            return { status: APISTATUS.SUCCESS, data: transformedData, message: "OK" }


        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to get scores as comments" }

        }
    }

    async getTotalScoreFromEvent(drinkID: number): Promise<ApiResult<{ score: number, event: string } | undefined>> {
        try {
            const { data, error } = await supabase
                .from(DrinkscoreService.EVENT_DRINKSCORES_VIEW)
                .select('score, event_title')
                .eq('drink_id', drinkID)

            if (error) return { status: APISTATUS.FAILURE, message: error.message };


            if (data && data.length > 0) {
                const avgEventScore = data.reduce((acc, value) => acc + value.score, 0) / data.length;
                const eventTitle = data[0].event_title;
                return {
                    status: APISTATUS.SUCCESS,
                    data: { score: avgEventScore, event: eventTitle ?? "Saknar titel" },
                    message: "OK"
                };
            } else {
                return { status: APISTATUS.SUCCESS, data: undefined, message: "No scores found" };
            }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINKSCORE_GET_FAIL_MESSAGE };
        }
    }



    async rateDrinkInEvent(userID: string, drinkID: number, eventID: number, score: number): Promise<ApiResult<EventDrinkscoreRow>> {
        try {
            const { data, error } = await supabase
                .from(DrinkscoreService.EVENT_DRINKSCORES_TABLE)
                .upsert(
                    {
                        voter_id: userID,
                        drink_id: drinkID,
                        score: score,
                        event_id: eventID
                    },
                )
                .select("*")
                .single()

            if (error) return { status: APISTATUS.FAILURE, message: DRINKSCORE_CREATED_FAIL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data, message: DRINKSCORE_CREATED_SUCCESS_MESSAGE }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINKSCORE_CREATED_FAIL_MESSAGE }
        }
    }

    async rateDrinkOutsideEvent(userID: string, drinkID: number, score: number, comment: string): Promise<ApiResult<DrinkscoreRow>> {

        try {
            const { data, error } = await supabase
                .from(DrinkscoreService.DRINKSCORES_TABLE)
                .insert(
                    {
                        voter_id: userID,
                        drink_id: drinkID,
                        score: score,
                        drinkscore_comment: comment,
                    },
                )
                .select()
                .single()


            if (error) return { status: APISTATUS.FAILURE, message: DRINKSCORE_CREATED_FAIL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data, message: DRINKSCORE_CREATED_SUCCESS_MESSAGE }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINKSCORE_CREATED_FAIL_MESSAGE }
        }
    }


    async getDrinkscoresForDrink(drinkID: number): Promise<ApiResult<DrinkscoreRow[]>> {
        try {
            const { data, error } = await supabase
                .from(DrinkscoreService.DRINKSCORES_TABLE)
                .select()
                .eq('drink_id', drinkID)


            if (error) return { status: APISTATUS.FAILURE, message: DRINKSCORE_GET_FAIL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data, message: DRINKSCORE_GET_SUCCESS_MESSAGE }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: DRINKSCORE_GET_FAIL_MESSAGE }
        }
    }

    async updateRating(userID: string, drinkID: number, newScore: number): Promise<ApiResult<boolean>> {
        try {
            const { data, error } = await supabase
                .from(DrinkscoreService.DRINKSCORES_TABLE)
                .update({ score: newScore })
                .match({ voter: userID, drink_id: drinkID });

            if (error) return { status: APISTATUS.FAILURE, message: DRINKSCORE_UPDATED_FAIL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data: true, message: DRINKSCORE_UPDATED_SUCCESS_MESSAGE };

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to update rating" };
        }
    }

    async deleteDrinkscore(userID: string, drinkID: number): Promise<ApiResult<null>> {
        try {
            const { data, error } = await supabase
                .from(DrinkscoreService.DRINKSCORES_TABLE)
                .delete()
                .eq('voter_id', userID)
                .eq('drink_id', drinkID)


            if (error) return { status: APISTATUS.FAILURE, message: DRINKSCORE_DELETED_FAIL_MESSAGE }

            return { status: APISTATUS.SUCCESS, data: null, message: DRINKSCORE_DELETED_SUCCESS_MESSAGE };

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to delete rating" };
        }
    }

    async deleteEventDrinkscore(userID: string, drinkID: number): Promise<ApiResult<null>> {
        try {
            const { data, error } = await supabase
                .from(DrinkscoreService.DRINKSCORES_TABLE)
                .delete()
                .match({ voter: userID, drink_id: drinkID });

            if (error) return { status: APISTATUS.FAILURE, message: error.message }

            return { status: APISTATUS.SUCCESS, data: null, message: "Event drink röstning borttagen" };

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to delete event rating" };
        }
    }

    async allVotesInForDrinkInEvent(eventID: number, drinkID: number): Promise<ApiResult<boolean>> {
        try {
            const { data, error, count: participants } = await supabase.from('event_participants').select().eq('event_id', eventID)

            if (error) return { status: APISTATUS.FAILURE, message: "Kunde inte hämta alla deltagare" }

            const { count, error: scoreError } = await supabase.from('drink_score').select('*').eq('drink_id', drinkID)

            if (scoreError) return { status: APISTATUS.FAILURE, message: "Kunde inte hämta alla röster" }

            const allVotesIn = participants === count

            return { status: APISTATUS.SUCCESS, data: allVotesIn, message: allVotesIn ? "Alla röster inne" : "Väntar fortfarande på röster" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Kunde inte utföra allVotesInForDrinkInEvent" }
        }
    }


   

    async getMedalsForUser(userID: string): Promise<ApiResult<MedalCollection>> {
        try {
            const { data: allEvents, error: allEventsError } = await supabase.from('events').select('id');
            if (allEventsError) {
                return { status: APISTATUS.FAILURE, message: allEventsError.message };
            }

            let bronzeMedals = 0;
            let silverMedals = 0;
            let goldMedals = 0;

            for (const event of allEvents) {
                const getHighscore = await this.getHighscoreForEvent(event.id);
                if (getHighscore.status === APISTATUS.FAILURE) {
                    continue;
                }
                const scores = getHighscore.data


                scores.forEach((score, index) => {
                    if (score.creator_id === userID) {
                        switch (index + 1) {
                            case 1:
                                goldMedals++;
                                break;
                            case 2:
                                silverMedals++;
                                break;
                            case 3:
                                bronzeMedals++;
                                break;
                        }
                    }
                });
            }
            

            const medals: MedalCollection = {
                bronzeMedals,
                silverMedals,
                goldMedals,
            };


            return { status: APISTATUS.SUCCESS, data: medals, message: "Medaljer hämtade" };

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to get user's medals" };
        }
    }


    async getHighscoreForEvent(eventID: number): Promise<ApiResult<ScoreboardItem[]>> {
        try {

            const { data, error } = await supabase
                .rpc('get_highscores_from_event', {
                    target_event_id: eventID
                }).returns<{
                    creator_id: string
                    creator_username: string
                    drink_id: number
                    drink_name: string
                    image_url: string
                    avg_score: number

                }[]>()


            if (error) return { status: APISTATUS.FAILURE, message: error.message }


            const parsed: ScoreboardItem[] = data.map(item => (
                {
                    creator_full_name: item.creator_username,
                    creator_id: item.creator_id,
                    drink_id: item.drink_id,
                    drink_name: item.drink_name,
                    avg_score: item.avg_score,
                    drink_image_url: item.image_url
                }
            ))

            return { status: APISTATUS.SUCCESS, data: parsed, message: "Poäng hämtade" }


        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to get highscore from event" }

        }
    }

    async getDrinkRank(drinkID: number): Promise<ApiResult<number>> {
        try {
            const { data: drink, error: drinkError } =
                await supabase.from('drinks').select(`drink_id, drink_name, event_id`).eq('drink_id', drinkID).single()

            if (drinkError) return { status: APISTATUS.FAILURE, message: drinkError.message }


            if (drink === null || !drink.event_id) return { status: APISTATUS.SUCCESS, data: -1, message: "Ingen rank för denna drink" }

            const getHighscore = await this.getHighscoreForEvent(drink.event_id)

            if (getHighscore.status === APISTATUS.FAILURE) return { status: APISTATUS.FAILURE, message: getHighscore.message }

            const rank = getHighscore.data.findIndex(item => item.drink_id === drink.drink_id)

            return { status: APISTATUS.SUCCESS, data: rank === -1 ? -1 : rank + 1, message: "Rank hämtad för drink" }

        } catch (error) {
            console.error(error);
            return { status: APISTATUS.FAILURE, message: "Failed to get drink rank" }
        }
    }





}


const createDrinkscoreService = () => (new DrinkscoreService)

export const drinkscoreService = createDrinkscoreService()