import React, { useState } from 'react';
import { Navbar, Nav, Offcanvas, Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "./offcanvas-navbar.css";
import { CiMenuBurger } from "react-icons/ci";
import { Button } from 'semantic-ui-react';
import LoginModal from '../modals/login-modal';
import { User } from '../../models/user';

type Props = {
    user: User;
    loggedIn: boolean;
    logout: () => void;
};

const OffcanvasNavbar = ({ user, loggedIn, logout }: Props) => {
    const [show, setShow] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const handleShowLogin = () => {
        setShowLoginModal(true);
        setShow(false); // Close the offcanvas when showing the login modal
    };
    const handleCloseLogin = () => setShowLoginModal(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Navbar bg="dark" variant="dark" sticky="top" className="offcanvas-navbar">
                <Container>
                    <div className="offcanvas-brand-container">
                        <img className="offcanvas-brand-image" src="/logo512.png" alt="Logo" />
                        <Navbar.Brand className="offcanvas-navbar-brand" as={Link} to="/">Drink Drink</Navbar.Brand>
                    </div>
                    <Button icon className="offcanvas-hamburger-button" onClick={handleShow}>
                        <CiMenuBurger size={30} />
                    </Button>
                </Container>
            </Navbar>
            <Offcanvas show={show} onHide={handleClose} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Meny</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="flex-column">
                        <NavLink className='offcanvas-nav-link' to="/drinks" onClick={handleClose}>Drinkar</NavLink>
                        {loggedIn ? (
                            <>
                                <NavLink className='offcanvas-nav-link' to="/events" onClick={handleClose}>Event</NavLink>
                                <NavLink className='offcanvas-nav-link' to="/profile" onClick={handleClose}>Profil</NavLink>
                                <span className="offcanvas-logged-in-as">Inloggad som:</span>
                                <NavLink className="offcanvas-profile-link" to="/profile" onClick={handleClose}>
                                    {user.avatar_url ? (
                                        <>
                                            <img src={user.avatar_url} alt="Avatar" className="offcanvas-avatar" />
                                            <span className="offcanvas-user-name">{user.full_name}</span>
                                        </>

                                    ) : (
                                        <span className="offcanvas-user-name">{user.full_name}</span>
                                    )}
                                </NavLink>
                                <Button className='offcanvas-btn-logout' variant="danger" onClick={() => { logout(); handleClose(); }}>Logga ut</Button>
                            </>
                        ) : (
                            <Button primary onClick={handleShowLogin}>Logga in</Button>
                        )}
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
            <LoginModal show={showLoginModal} handleClose={handleCloseLogin} />
        </>
    );
};

export default OffcanvasNavbar;
