import React from 'react';
import EventList from '../../components/events/event-list';
import { useEvent } from '../../context/event-context';
import "../../css/event-page.css";
import { Outlet, useNavigate } from 'react-router-dom';
import { DrinkEvent } from '../../models/event';

const EventPage: React.FC = () => {
    const { allEvents, onSetSelectedDrinkEvent } = useEvent();
    const navigate = useNavigate();

    const handleDrinkSelect = (event: DrinkEvent) => {
        onSetSelectedDrinkEvent(event);
        navigate(`/events/${event.event_id}`);
    };


    return (
        <div className="page-container">

            <EventList events={allEvents} onEventClick={handleDrinkSelect} />
            <Outlet />
        </div>
    );
};

export default EventPage;
