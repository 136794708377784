import { useState, useRef, useEffect } from "react";
import { DrinkIngredient, DrinkInsert, DrinkUnit } from "../../models/drink";
import { Alert, Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FaCamera } from "react-icons/fa";
import { ImageCropper } from "../../components/modals/image-cropper";
import { useUser } from "../../context/user-context";
import { useNavigate } from "react-router-dom";
import { APISTATUS } from "../../types/api";

export const AddDrinkPage = () => {
    const { createDrink, loadingOperation } = useUser();
    const navigate = useNavigate();

    const [drinkName, setDrinkName] = useState('');
    const [instructions, setInstructions] = useState('');
    const [ingredients, setIngredients] = useState<DrinkIngredient[]>([]);
    const [drinkImageUrl, setDrinkImageUrl] = useState<string>();
    const [imageFile, setImageFile] = useState<File>();
    const [error, setError] = useState<string | null>(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);


    
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (unsavedChanges) {
                event.preventDefault();
                return (event.returnValue = '');
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [unsavedChanges]);

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setDrinkImageUrl(reader.result as string);
                setShowCropModal(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOnCropSaved = (croppedImage: string, imageFile: File) => {
        setDrinkImageUrl(croppedImage);
        setImageFile(imageFile);
        setShowCropModal(false);
        setUnsavedChanges(true);
    };

    const handleAddIngredient = () => {
        setIngredients(prev => [...prev, { name: '', amount: 0, unit: DrinkUnit.CL }]);
        setUnsavedChanges(true);
    };

    const handleRemoveIngredient = (index: number) => {
        const newIngredients = ingredients.filter((_, i) => i !== index);
        setIngredients(newIngredients);
        setUnsavedChanges(true);
    };

    const handleIngredientChange = (index: number, field: keyof DrinkIngredient, value: any) => {
        const newIngredients = [...ingredients];
        newIngredients[index] = { ...newIngredients[index], [field]: value };
        setIngredients(newIngredients);
        setUnsavedChanges(true);
    };

    const handleCreateDrink = async () => {
        if (!drinkName.trim()) {
            setError('Drinken måste ha ett namn!');
            return;
        }
        setError(null);
        const newDrink: DrinkInsert = {
            drink_name: drinkName,
            ingredients,
            instructions,
            image_url: drinkImageUrl
        };
        const res = await createDrink(newDrink, imageFile);

        if (res.status === APISTATUS.SUCCESS) {
            setUnsavedChanges(false);
            navigate(-1);
        }
    };

    const handleFormChange = () => {
        setUnsavedChanges(true);
    };

    const handleModalClose = () => {
        setShowUnsavedChangesModal(false);
    };

    const handleModalLeave = () => {
        setUnsavedChanges(false);
        navigate(-1);
    };

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        await handleCreateDrink();
    };

    return (
        <div className='edit-drink-page'>
            <Container className="edit-drink-content">
                <h2>Skapa ny drink</h2>
                <Form onSubmit={handleFormSubmit} onChange={handleFormChange}>
                    <Form.Group className="mt-3">
                        <div className="edit-drink-image-container" onClick={handleImageClick}>
                            {drinkImageUrl ? (
                                <img src={drinkImageUrl} alt="Drink" className="edit-drink-image" />
                            ) : (
                                <div className="edit-drink-image-placeholder">
                                    <FaCamera />
                                </div>
                            )}
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            className="edit-drink-hidden-file-input"
                            onChange={handleImageChange}
                        />
                    </Form.Group>
                    <ImageCropper
                        showModal={showCropModal}
                        imgURL={drinkImageUrl ?? ''}
                        onModalClose={() => setShowCropModal(false)}
                        onSaveHandler={handleOnCropSaved}
                        fileName={`drink_image_${Date.now()}.jpg`}
                    />
                    <Form.Group controlId="formDrinkName">
                        <Form.Label>Drinkens namn</Form.Label>
                        <Form.Control
                            type="text"
                            name="drink_name"
                            value={drinkName}
                            onChange={(event) => setDrinkName(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formInstructions">
                        <Form.Label>Instruktioner</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="instructions"
                            value={instructions}
                            onChange={(e) => setInstructions(e.target.value)}
                            rows={3}
                        />
                    </Form.Group>
                    <Form.Group controlId="formIngredients">
                        <Form.Label>Ingredienser</Form.Label>
                        {ingredients.map((ingredient, index) => (
                            <Row key={index} className="mb-3">
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        value={ingredient.name}
                                        onChange={e => handleIngredientChange(index, 'name', e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        placeholder="Amount"
                                        value={ingredient.amount}
                                        onChange={e => handleIngredientChange(index, 'amount', Number(e.target.value))}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        as="select"
                                        value={ingredient.unit}
                                        onChange={e => handleIngredientChange(index, 'unit', e.target.value as DrinkUnit)}
                                    >
                                        {Object.values(DrinkUnit).map(unit => (
                                            <option key={unit} value={unit}>{unit}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                                <Col xs="auto">
                                    <Button variant="danger" onClick={() => handleRemoveIngredient(index)}>Ta bort</Button>
                                </Col>
                            </Row>
                        ))}
                        <div className="mt-2">
                            <Button variant="secondary" onClick={handleAddIngredient}>Lägg till ingrediens</Button>
                        </div>
                    </Form.Group>

                    {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                    <Container className="edit-drink-button-content mt-3">
                        <Button variant="primary" type="submit" disabled={loadingOperation}>Skapa drink</Button>
                    </Container>
                </Form>
            </Container>
            <Modal show={showUnsavedChangesModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Osparade ändringar</Modal.Title>
                </Modal.Header>
                <Modal.Body>Du har osparade ändringar. Är du säker på att du vill lämna sidan utan att spara?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Avbryt
                    </Button>
                    <Button variant="danger" onClick={handleModalLeave}>
                        Lämna utan att spara
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AddDrinkPage;
