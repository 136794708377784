import React, { useState } from "react";
import styles from "../../css/drink-card.module.css";
import { Drink } from "../../models/drink";


interface DrinkCardProps {
    drink: Drink;
    onDrinkSelect: (drink: Drink) => void;
    onSetPublic?: () => void;
    showProfile?: boolean;
    onlyFirstName?: boolean;
}

export const DrinkCard: React.FC<DrinkCardProps> = ({ drink, onDrinkSelect, showProfile, onSetPublic }) => {
    const [imageHeight, setImageHeight] = useState<number>(0);

    const onImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        const { height } = event.currentTarget;
        setImageHeight(height);
    };

    const fontSize = Math.max(100, imageHeight * 0.06);

    return (
        <div className={styles.cardContainer} onClick={() => onDrinkSelect(drink)}>
            <div className={styles.imageContainer}>
                {showProfile && drink.creator_avatar_url && (
                    <img src={drink.creator_avatar_url} alt="Creator Avatar" className={styles.avatarImage} />
                )}

                {drink.drink_image_url ? (
                    <img className={styles.drinkImage} src={drink.drink_image_url} alt="Drink" onLoad={onImageLoad} />
                ) : (
                    <div className={styles.placeholderIconContainer}>
                        <div className={styles.placeholderIcon}>🍹</div>
                    </div>
                )}

                <div className={styles.titleOverlay} >
                    <div className={styles.drinkTitle}>{drink.drink_name}</div>
                </div>
            </div>
        </div>
    );
};

export default DrinkCard;
