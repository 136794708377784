import React from 'react';
import { DrinkEvent } from "../../models/event";
import EventCard from "./event-card";

type EventListProps = {
    events: DrinkEvent[];
    onEventClick: (event: DrinkEvent) => void;
};

export const EventList: React.FC<EventListProps> = ({ events, onEventClick }) => {
    return (
        <div >
            {events.map((event) => (
                <EventCard 
                    event={event} 
                    key={event.event_id}  
                    onEventClick={onEventClick}
                />
            ))}
        </div>
    );
};

export default EventList;
