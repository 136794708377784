import React from 'react';
import { DrinkEvent } from '../../models/event';
import { Card, Button } from 'react-bootstrap';

type EventProps = {
    event: DrinkEvent;
    onEventClick: (event: DrinkEvent) => void;
};

export const EventCard: React.FC<EventProps> = ({ event, onEventClick }) => {
    const whenDate = new Date(event.when_date);

    const formatDescription = (description: string) => {
        return description.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <Card style={{ maxWidth: "400px", minHeight: "500px" }}>
            <Card.Img src={event.event_image_url || 'https://awildgeographer.files.wordpress.com/2015/02/john_muir_glacier.jpg'} alt="Event" />
            <Card.Body>
                <Card.Title>{event.title}</Card.Title>
                <Card.Text>
                    {formatDescription(event.description)}
                </Card.Text>
                <Card.Text>
                    <p>När: {whenDate.toLocaleDateString()}</p>
                    <p>Skapad av: {event.creator_full_name}</p>
                </Card.Text>
                <Button onClick={() => onEventClick(event)}>Besök</Button>
            </Card.Body>
        </Card>
    );
};

export default EventCard;
