export enum APISTATUS {
    SUCCESS,
    FAILURE
}

export type StorageBucket = "avatars" | "drink-pictures" | "event-pictures"

export type Success<T> = { status: APISTATUS.SUCCESS, data: T, message: string }
export type Failure = { status: APISTATUS.FAILURE, message: string };


export type ApiResult<T> = Success<T> | Failure;

export interface Domain<D> {
    toEntity<E>(d: D): E
}

export interface Entity<E> {
    toDomain<D>(e: E): D
}

export interface Pagination {
    start: number;
    end: number;
}


export type SignedImageUrl = {id: string, url: string}