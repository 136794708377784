import React from 'react';
import { FaStar } from 'react-icons/fa';
import { IoMdStar } from "react-icons/io";
import styles from './score-display.module.css';

type Props = {
  totalScoreForDrink?: number;
  eventScore?: { score: number; event: string };
};

const ScoreDisplay: React.FC<Props> = ({ totalScoreForDrink, eventScore }) => {
  return (
    <div>
      {totalScoreForDrink && (
        <div className={styles.scoreContainer}>
          <p>{totalScoreForDrink}/10</p>
          <IoMdStar className="icon" color="#FFD700"/>
        </div>
      )}

      {eventScore && (
        <div className={styles.eventScoreContainer}>
          <p>{eventScore.score}/10 - {eventScore.event}</p>
          <IoMdStar  className="icon" />
        </div>
      )}
    </div>
  );
};

export default ScoreDisplay;
