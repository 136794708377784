import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useEvent } from '../../context/event-context';
import { useCurrentEvent } from '../../context/current-event-context';
import { Button, ListGroup } from 'react-bootstrap';
import "./css/event-detail-page.css";
import { eventService } from '../../services/EventService';
import { APISTATUS } from '../../types/api';
import { supabase } from '../../supabase/supabase';
import { RealtimePostgresUpdatePayload, RealtimePostgresInsertPayload, RealtimePostgresDeletePayload, RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { EventDrinkscoreRow } from '../../models/drink-score';
import { EventEntity, ParticipantEntity } from '../../models/event';
import { useNavigate, useParams } from 'react-router-dom';
import { VotingModal } from '../../components/events/voting-modal';
import CurrentDrinkItem from '../../components/events/current-drink-item';
import VotingList from '../../components/events/voting-list';
import CurrentEventDrinkComponent from './current-event-drink-page';
import CurrentEventScoreboard from './current-event-scoreboard';

export const EventDetailPage = () => {
    const { allParticipants, voters, isParticipant, isOwner, loadingContent,
        loadingProcess, currentDrink, drinkScores, allDrinkScoresForCurrentDrink, canPublishNewDrink,
        rateCurrentDrink, joinEvent, leaveEvent, listenForJoiners, listenForLeavers,
        listenForScoreUpdates, listenForNewPublishedDrinks, removePublishedDrinkFromEvent } = useCurrentEvent()

    const { selectedDrinkEvent, onSetSelectedDrinkEvent } = useEvent()
    const navigate = useNavigate();

    const [modalVisible, setModalVisible] = useState(false);


    useEffect(() => {
        const eventChannel = supabase.channel(`event-${selectedDrinkEvent?.event_id}`)
            .on('postgres_changes', {
                event: 'UPDATE', schema: 'public', table: 'events', filter: `id=eq.${selectedDrinkEvent?.event_id}`
            }, (payload: RealtimePostgresUpdatePayload<EventEntity>) => {

                listenForNewPublishedDrinks(payload)
            })
            .on('postgres_changes', {
                event: 'INSERT', schema: 'public', table: 'event_participants', filter: `event_id=eq.${selectedDrinkEvent?.event_id}`
            }, (payload: RealtimePostgresInsertPayload<ParticipantEntity>) => {

                listenForJoiners(payload)
            })
            .on('postgres_changes', {
                event: 'DELETE', schema: 'public', table: 'event_participants', filter: `event_id=eq.${selectedDrinkEvent?.event_id}`
            }, (payload: RealtimePostgresDeletePayload<ParticipantEntity>) => {

                listenForLeavers(payload)

            })
            .on('postgres_changes', {
                event: '*', schema: 'public', table: "event_drinkscores", filter: `event_id=eq.${selectedDrinkEvent?.event_id}`
            }, (payload: RealtimePostgresChangesPayload<EventDrinkscoreRow>) => {

                listenForScoreUpdates(payload)

            })
            .subscribe()


        return () => { supabase.removeChannel(eventChannel) }
    }, [supabase])

    const handleRateCurrentCrink = async (score: number) => {
        await rateCurrentDrink(score)
    }


    const handleJoinEvent = async () => {
        if (selectedDrinkEvent?.event_id) {

            await joinEvent(selectedDrinkEvent?.event_id)
        }
    }

    const handleLeaveEvent = async () => {
        if (selectedDrinkEvent?.event_id) {
            await leaveEvent(selectedDrinkEvent?.event_id)

        }
    }

    // TODO: Implement
    const handleStartEvent = async () => {
        console.log("Start event");
    }

    const handleNavigateToPublishScreen = () => {
        if (selectedDrinkEvent)
            navigate(`/events/${selectedDrinkEvent.event_id}/publishdrink`)

    }

    const handleEditEvent = () => {
        if (selectedDrinkEvent)
            navigate(`/events/${selectedDrinkEvent.event_id}/edit`)
    }

    // For testing
    const handleRemoveLastPublishedDrink = async () => {
        await removePublishedDrinkFromEvent()
    }

    return (
        <div className="event-detail-page">
            <div className="content">
                <Tabs
                    defaultActiveKey="participants"
                    id="justify-tab-example"
                    className="mb-3"
                    justify
                >
                    <Tab eventKey="participants" title="Deltagare">
                        <div>
                            <h1>Välkommen till {selectedDrinkEvent?.title}!</h1>
                            <h2>Deltagare:</h2>
                            <ListGroup>
                                {allParticipants?.map((user) => (
                                    <ListGroup.Item key={user.user_id}>{user.full_name}</ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                        <div className='d-flex justify-content-center pt-3'>
                            {isParticipant && selectedDrinkEvent ? (
                                <Button onClick={() => leaveEvent(selectedDrinkEvent.event_id)}>Lämna evenemang</Button>
                            ) : (
                                <Button onClick={() => joinEvent(selectedDrinkEvent!.event_id)}>Gå med evenemang</Button>
                            )}

                        </div>
                    </Tab>

                    <Tab eventKey="votes" title="Röstning">
                        <CurrentEventDrinkComponent
                            currentDrink={currentDrink}
                            voters={voters}
                            allowedToPublish={canPublishNewDrink}
                            onPublishDrinkClick={handleNavigateToPublishScreen}
                            onRateDrink={handleRateCurrentCrink}
                        />
                    </Tab>

                    <Tab eventKey="scoreboard" title="Poängtavla">
                        <CurrentEventScoreboard scores={drinkScores} />
                    </Tab>

                </Tabs>
            </div>
        </div>
    );
}
