import React, { useEffect, useRef, useState } from 'react';
import './css/edit-drink-page.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DrinkIngredient, DrinkUnit, DrinkUpdate } from '../../models/drink';
import { useUser } from '../../context/user-context';
import { drinkService } from '../../services/DrinkService';
import { APISTATUS } from '../../types/api';
import { Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { ImageCropper } from '../../components/modals/image-cropper';
import { FaCamera } from 'react-icons/fa';

export const EditDrinkPage: React.FC = () => {
    const navigate = useNavigate();
    const { drinkID } = useParams();
    const { state } = useLocation();
    const { updateDrink, deleteDrink, loadingOperation } = useUser();

    const [drinkId, setDrinkId] = useState<number | null>(null);
    const [drinkName, setDrinkName] = useState('');
    const [instructions, setInstructions] = useState('');
    const [ingredients, setIngredients] = useState<DrinkIngredient[]>([]);
    const [drinkImageUrl, setDrinkImageUrl] = useState<string | null>('');
    const [imageFile, setImageFile] = useState<File>();
    const [error, setError] = useState<string | null>(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (state && state.drink) {
                setDrinkId(Number(state.drink.drink_id));
                setDrinkName(state.drink.drink_name);
                setInstructions(state.drink.instructions);
                setIngredients(state.drink.ingredients);
                setDrinkImageUrl(state.drink.drink_image_url);
                return;
            }

            if (!drinkID || Number.isNaN(Number(drinkID))) return;
            const res = await drinkService.getDrinkById(Number(drinkID));

            if (res.status === APISTATUS.SUCCESS && res.data) {
                setDrinkId(Number(res.data.drink_id));
                setDrinkName(res.data.drink_name);
                setInstructions(res.data.instructions ?? '');
                setIngredients(res.data.ingredients);
                setDrinkImageUrl(res.data.drink_image_url ?? '');
            } else {
                // Handle errors appropriately (e.g., navigate to a 404 page)
            }
        };

        fetchData();
    }, [drinkID, state]);

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setDrinkImageUrl(reader.result as string);
                setShowCropModal(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOnCropSaved = (croppedImage: string, imageFile: File) => {
        setDrinkImageUrl(croppedImage);
        setImageFile(imageFile);
        setShowCropModal(false);
    };

    const handleAddIngredient = () => {
        setIngredients(prev => [...prev, { name: '', amount: 0, unit: DrinkUnit.CL }]);
    };

    const handleRemoveIngredient = (index: number) => {
        const newIngredients = ingredients.filter((_, i) => i !== index);
        setIngredients(newIngredients);
    };

    const handleIngredientChange = (index: number, field: keyof DrinkIngredient, value: any) => {
        const newIngredients = [...ingredients];
        newIngredients[index] = { ...newIngredients[index], [field]: value };
        setIngredients(newIngredients);
    };

    const handleUpdateDrink = async () => {
        if (!drinkName.trim()) {
            setError('Drinken måste ha ett namn!');
            return;
        }
        setError(null);

        if (drinkId === null || Number.isNaN(drinkId)) return;

        const drinkUpdate: DrinkUpdate = {
            drink_id: drinkId,
            drink_name: drinkName,
            ingredients,
            instructions,
            image_url: drinkImageUrl ?? ''
        };

        const res = await updateDrink(drinkId, drinkUpdate, imageFile);

        if (res.status === APISTATUS.SUCCESS) {
            navigate(-1);
        }
    };

    const handleDeleteDrink = async () => {
        if (drinkId === null) return;
        const res = await deleteDrink(drinkId);
        if (res.status === APISTATUS.SUCCESS) {
            navigate(-1);
        }
        setShowDeleteModal(false);
    };

    return (
        <div className='edit-drink-page'>
            <Container className="edit-drink-content">
                <h2>{`Uppdatera ${drinkName}`}</h2>
                <Form.Group className="mt-3">
                    <div className="edit-drink-image-container" onClick={handleImageClick}>
                        {drinkImageUrl ? (
                            <img src={drinkImageUrl} alt="Drink" className="edit-drink-image" />
                        ) : (
                            <div className="edit-drink-image-placeholder">
                                <FaCamera />
                            </div>
                        )}
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        className="edit-drink-hidden-file-input"
                        onChange={handleImageChange}
                    />
                </Form.Group>
                <ImageCropper
                    showModal={showCropModal}
                    imgURL={drinkImageUrl ?? ''}
                    onModalClose={() => setShowCropModal(false)}
                    onSaveHandler={handleOnCropSaved}
                    fileName={`drink_image_${Date.now()}.jpg`}
                />
                <Form>
                    <Form.Group controlId="formDrinkName">
                        <Form.Label>Drinkens namn</Form.Label>
                        <Form.Control
                            type="text"
                            name="drink_name"
                            value={drinkName}
                            onChange={(event) => setDrinkName(event.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formInstructions">
                        <Form.Label>Instruktioner</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="instructions"
                            value={instructions}
                            onChange={(e) => setInstructions(e.target.value)}
                            rows={3}
                        />
                    </Form.Group>
                    <Form.Group controlId="formIngredients">
                        <Form.Label>Ingredienser</Form.Label>
                        {ingredients.map((ingredient, index) => (
                            <Row key={index} className="mb-3">
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        value={ingredient.name}
                                        onChange={e => handleIngredientChange(index, 'name', e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        placeholder="Amount"
                                        value={ingredient.amount}
                                        onChange={e => handleIngredientChange(index, 'amount', Number(e.target.value))}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        as="select"
                                        value={ingredient.unit}
                                        onChange={e => handleIngredientChange(index, 'unit', e.target.value as DrinkUnit)}
                                    >
                                        {Object.values(DrinkUnit).map(unit => (
                                            <option key={unit} value={unit}>{unit}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                                <Col xs="auto">
                                    <Button variant="danger" onClick={() => handleRemoveIngredient(index)}>Ta bort</Button>
                                </Col>
                            </Row>
                        ))}
                        <div className="mt-2">
                            <Button variant="secondary" onClick={handleAddIngredient}>Lägg till ingrediens</Button>
                        </div>
                    </Form.Group>

                    {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                    <Container className="edit-drink-button-content mt-3">
                        <Button variant="danger" onClick={() => setShowDeleteModal(true)} disabled={loadingOperation}>Ta bort drink</Button>
                        <Button variant="primary" onClick={handleUpdateDrink} disabled={loadingOperation}>Uppdatera</Button>
                    </Container>
                </Form>
            </Container>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Bekräfta radering</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Är du säker på att du vill radera denna drink?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Avbryt
                    </Button>
                    <Button variant="danger" onClick={handleDeleteDrink}>
                        Radera
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EditDrinkPage;
